import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { CreateAnalyticsEvent } from '../analytics/index.tsx';
import type { JpdCJNIneligibilityReason } from './eligibility.tsx';
import { CROSS_JOIN_NUDGE_JIRA_JPD_EXPERIMENT_KEY, getExperimentCohort } from './feature-flag.tsx';

export const fireEligibilityChecked = (
	createAnalyticsEvent: CreateAnalyticsEvent,
	reason: JpdCJNIneligibilityReason | 'ELIGIBLE',
) =>
	fireTrackAnalytics(createAnalyticsEvent({}), 'eligibility checked', {
		flagKey: CROSS_JOIN_NUDGE_JIRA_JPD_EXPERIMENT_KEY,
		ineligibilityReason: reason,
	});

export const fireFeatureExposed = (createAnalyticsEvent: CreateAnalyticsEvent) => {
	const cohort = getExperimentCohort({ fireExposure: true });
	fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
		flagKey: CROSS_JOIN_NUDGE_JIRA_JPD_EXPERIMENT_KEY,
		cohort,
	});
};
