import React from 'react';
import type { BadgeProps as BadgeType } from '@atlaskit/badge';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import { PACKAGE_NAME } from '../../../../common/constants.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const Notifications = lazyAfterPaint(
	() => import(/* webpackChunkName: "async-atlaskit-badge" */ '@atlaskit/badge'),
	{ ssr: false },
);

export const LazyNotifications = (props: BadgeType) => (
	<JSErrorBoundary id="help.atlaskit-badge" packageName={PACKAGE_NAME} teamName="navigation">
		<Placeholder name="lazy-notification-badge" fallback={null}>
			<Notifications {...props} />
		</Placeholder>
	</JSErrorBoundary>
);
