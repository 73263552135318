import React from 'react';
import { useForgePersonalSettingsItems } from '@atlassian/jira-forge-personal-settings-items-store/src/index.tsx';
import { ForgeScreenEvent } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { TriggerAnalyticsWrapper } from '@atlassian/jira-forge-ui-analytics/src/ui/personal-settings-page/index.tsx';
import { PERSONAL_SETTINGS_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { ForgeIcon } from '@atlassian/jira-forge-ui-action-apps/src/ui/forge-icon/index.tsx';
import { ExtensionTitle } from '@atlassian/jira-forge-ui-extension-title/src/ui/index.tsx';
import { parseExtensionId } from '@atlassian/jira-forge-ui-utils-internal/src/utils/parse-extension-id/index.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { getAppsPersonalSettingsPath } from '@atlassian/jira-personal-settings-paths/src/index.tsx';
import { MenuItem } from '../../../../common/ui/menu/item/index.tsx';
import { MenuListItem } from '../../../../common/ui/menu/list/index.tsx';
import type { ExtraItemsProps } from '../extra-items/index.tsx';

const BaseForgeItems = ({ startPosition, testIdPrefix, itemAriaLabel }: ExtraItemsProps) => {
	const [{ items: forgeStoreItems }] = useForgePersonalSettingsItems();
	const shouldTriggerForgeScreenEvent = forgeStoreItems && forgeStoreItems.length > 0;

	return (
		<>
			{(forgeStoreItems || []).map((item, idx) => {
				const { appId, envId } = parseExtensionId(item.id);

				return (
					<MenuListItem key={item.id} ariaLabel={itemAriaLabel || ''}>
						<MenuItem
							analytics={{
								actionSubjectId: 'profileNavigationMenuItem',
								menuItemId: item.id,
								menuItemType: 'jira',
								position: startPosition + idx,
							}}
							href={`${getAppsPersonalSettingsPath()}/${appId}/${envId}`}
							testId={testIdConcat(testIdPrefix, `item-addon-${idx}`)}
							iconBefore={<ForgeIcon url={item.avatarUrl} />}
						>
							<ExtensionTitle
								extensionEnvType={item.environmentType}
								extensionEnvKey={item.environmentKey}
								extensionName={item.title}
								autoTruncate
							/>
						</MenuItem>
					</MenuListItem>
				);
			})}
			{shouldTriggerForgeScreenEvent && (
				<ForgeScreenEvent
					attributes={{
						moduleType: PERSONAL_SETTINGS_PAGE_MODULE,
					}}
				/>
			)}
		</>
	);
};

export const ForgeItems = (props: ExtraItemsProps) => (
	<TriggerAnalyticsWrapper>
		<BaseForgeItems {...props} />
	</TriggerAnalyticsWrapper>
);
