/** @jsx jsx */
import React, { useMemo, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Inline, Pressable, Stack, xcss } from '@atlaskit/primitives';
import { B300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { type FlagComponentProps, InfoFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { FlagCompatibleAvatarGroup } from '../../common/ui/flag-compatible-avatar-group/index.tsx';
import { messages } from '../../common/ui/request-access-prompt-flag/messages.tsx';
import { noop } from '../../common/utils.tsx';
import type { Collaborators } from '../../services/get-collaborators/types.tsx';

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
	users: Collaborators;
	onOptoutAndDismiss: () => unknown;
	onSubmit: () => Promise<void>;
	onSnoozeAndDismiss: () => unknown;
	featureVisitedCount: number;
};

type InfoFlagProps = React.ComponentProps<typeof InfoFlag>;

export const RequestAccessPromptFlag = ({
	users,
	onSubmit,
	onDismiss,
	onOptoutAndDismiss,
	onSnoozeAndDismiss,
	featureVisitedCount,
	...props
}: Props) => {
	const handleOnSubmit = useCallback(async () => {
		await onSubmit();
	}, [onSubmit]);
	const { formatMessage } = useIntl();
	const customFlagFooterProps = useMemo(
		(): Pick<InfoFlagProps, 'actions' | 'linkComponent'> => ({
			actions: [
				{
					content: '',
					onClick: noop,
				},
			],
			linkComponent: () => {
				let secondETA = null;

				if (featureVisitedCount === 1) {
					secondETA = (
						<Pressable xcss={linkButtonStyles} onClick={onSnoozeAndDismiss}>
							{formatMessage(messages.remindMeLaterButton)}
						</Pressable>
					);
				} else if (featureVisitedCount > 1) {
					secondETA = (
						<Pressable xcss={linkButtonStyles} onClick={onOptoutAndDismiss}>
							{formatMessage(messages.doNotShowAgainButton)}
						</Pressable>
					);
				}

				return (
					<Inline alignInline="start" grow="fill" alignBlock="center">
						<Pressable xcss={linkButtonStyles} onClick={handleOnSubmit}>
							{formatMessage(messages.getStartedButton)}
						</Pressable>
						{!!secondETA && <span css={linkButtonSeparatorStyles} />}
						{secondETA}
					</Inline>
				);
			},
		}),
		[featureVisitedCount, handleOnSubmit, formatMessage, onOptoutAndDismiss, onSnoozeAndDismiss],
	);

	const customIcon = useMemo(() => {
		return users.length > 0 ? (
			<FlagCompatibleAvatarGroup
				alignment="horizontal"
				maxAvatarCount={2}
				tooltipPosition="bottom"
				data={users.map((user) => ({
					name: user.name,
					src: user.avatar,
					appearance: 'circle',
				}))}
				size="small"
				boundariesElement="scrollParent"
			/>
		) : (
			<InfoIcon primaryColor={token('color.icon.information', B300)} label="" />
		);
	}, [users]);

	const infoFlagTitle = formatMessage(messages.titleJpdNudge);

	return (
		<InfoFlag
			{...props}
			{...customFlagFooterProps}
			onDismissed={featureVisitedCount === 0 ? onDismiss : onSnoozeAndDismiss}
			title={infoFlagTitle}
			description={
				<Stack space="space.100">
					<p css={mainContentContainerStyles}>{formatMessage(messages.descriptionJpdNudge)}</p>
				</Stack>
			}
			icon={customIcon}
		/>
	);
};

const mainContentContainerStyles = css({
	display: '-webkit-box',
	'-webkit-line-clamp': '3',
	'-webkit-box-orient': 'vertical',
	overflow: 'visible',
	margin: '0',
	marginBottom: token('space.100', '8px'),
});

const linkButtonStyles = xcss({
	maxWidth: '150',
	maxHeight: '3lh',
	overflow: 'hidden',
	color: 'color.link',
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: '0',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	cursor: 'pointer',
	padding: '0',
	':hover': {
		textDecoration: 'underline',
	},
});

const linkButtonSeparatorStyles = css({
	'&::before': {
		content: '·',
		marginLeft: token('space.150', '12px'),
		marginRight: token('space.150', '12px'),
	},
});
