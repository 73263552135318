export type Environment = 'prod' | 'staging' | 'dev' | 'local' | null;

export const getWindow = () => window;

export const getSiteUrl = (currentWindow: Window) => {
	if (!currentWindow.__SERVER__) {
		return `${currentWindow.location.protocol}//${currentWindow.location.host}`;
	}
	return '';
};

export const getIdentityEnv = (env: Environment) => {
	switch (env) {
		case 'local':
			return 'staging';
		case 'dev':
			return 'staging';
		case 'staging':
			return 'staging';
		case 'prod':
			return 'prod';
		default:
			return 'prod';
	}
};
