import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@compiled/react';
import noop from 'lodash/noop';
import differenceInDays from 'date-fns/differenceInDays';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import {
	InvitePeopleModalContextProvider,
	useInvitePeopleModal,
} from '@atlassian/invite-people/InvitePeopleModalContext';
import type { InvitePeopleProps, OnSubmitData } from '@atlassian/invite-people/types';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { inviteBusinessTeamsModalEntryPoint } from '@atlassian/jira-business-invite-business-teams-modal/entrypoint.tsx';
import { usePersonalizationProvider } from '@atlassian/jira-business-sample-project/src/services/personalization/index.tsx';
import { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	VARIATION,
	NOT_ENROLLED,
} from '@atlassian/jira-common-util-get-experiment-group/src/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useFlagsService } from '@atlassian/jira-flags';
import ErrorFlag from '@atlassian/jira-flags/src/common/ui/components/error-flag';
import SuccessFlag from '@atlassian/jira-flags/src/common/ui/components/success-flag';
import WarningFlag from '@atlassian/jira-flags/src/common/ui/components/warning-flag';
import { projectTypeToSubProduct } from '@atlassian/jira-invite-people-drawer/src/ui/content/utils.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useTopMenus } from '@atlassian/jira-navigation-apps-common/src/controllers/top-menus/index.tsx';
import { useProjectSidebarNavigation } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/common/project-sidebar-navigation-resource/index.tsx';
import { JWM_INVITE_TEAM_NUDGE_ID } from '@atlassian/jira-onboarding-core/src/constants.tsx';
import { useIsTailoredOnboardingM2ForQuickstartEnabled } from '@atlassian/jira-onboarding-core/src/controllers/use-fetch-onboarding-personalisation/index.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useOrgId } from '@atlassian/jira-router-resources-navigation-org-id/src/index.tsx';
import { FREE_EDITION, STANDARD_EDITION } from '@atlassian/jira-shared-types/src/edition.tsx';
import { useRegisterNudgeTarget } from '@atlassian/jira-software-onboarding-nudges--next/src/controllers/register-nudge-target/index.tsx';
import { InviteTeamNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/jwm-invite-team-tour/async.tsx';
import { NavigationPeopleNavItemWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/navigation-people/async.tsx';
import { useHasOpened } from '@atlassian/jira-software-onboarding-quickstart-has-opened/src/services/has-opened/index.tsx';
import { useFirstActivationDateMs } from '@atlassian/jira-tenant-context-controller/src/components/first-activation-date-ms/index.tsx';
import { useIsAnonymous } from '@atlassian/jira-tenant-context-controller/src/components/is-anonymous/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import PeopleMenu from '@atlassian/people-menu';
import type { TeamCreateDialogProps } from '@atlassian/people-teams';
import { useRouterActions } from '@atlassian/react-resource-router';
import { NAVIGATION_ITEM_ID, PACKAGE_NAME } from '../../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../../common/ui/error-boundary/index.tsx';
import { ChevronRightIcon } from '../../../common/ui/menu/trigger/main.tsx';
import { FullHeightTextColorWrapper } from '../../../common/ui/nudges/styled.tsx';
import { useFromSpotlightTour } from '../../../controllers/from-spotlight-tour/index.tsx';
import { useMenuHighlighted } from '../../../controllers/highlight/main.tsx';
import { useNavigationItemAnalytics } from '../../../controllers/navigation-item-analytics/main.tsx';
import {
	useBrowseUsersGroupsPermissions,
	useBrowseUsersGroupsPermissionsNext,
} from '../../../controllers/people/index.tsx';
import { getInvitedJswUsers } from '../../../services/show-auto-upgraded-modal/index.tsx';

const STORAGE_KEY = 'jwm.invite-business-teams-modal-viwed-timestamp';
const SITE_USER_TRAIT_HAS_VIEW_EXPERIMENT = 'jira_screen_viewed_invitebusinessteamsmodal_first';
const STORAGE_PROVIDER_KEY = 'jira_invite-business-teams-modal';

export const localStorage = createLocalStorageProvider(STORAGE_PROVIDER_KEY);

const PeopleMenuDropdown = () => {
	const [inviteBusinessTeamsModalData, setInviteBusinessTeamsModalData] = useState({
		isOpen: false,
		userCounts: 0,
	});

	const [isMenuOpen, { off: closeMenu, on: openMenu }] = useTopMenus(MENU_ID.PEOPLE);
	const baseUrl = '';
	const { showFlag } = useFlagsService();
	const route = useCurrentRoute();
	const { push } = useRouterActions();
	const isHighlighted = useMenuHighlighted(MENU_ID.PEOPLE);
	const triggerAnalytics = useNavigationItemAnalytics({
		navigationItemId: NAVIGATION_ITEM_ID.PEOPLE,
	});
	const [isEventFromSpotlightTour] = useFromSpotlightTour('spotlight');
	const checkUsersGroupPermission = useBrowseUsersGroupsPermissions();
	const checkUsersGroupPermissionNext = useBrowseUsersGroupsPermissionsNext(isMenuOpen);
	const isEnabledForTailoredOnboardingM2 = fg('jira_tailored_onboarding_jtbd_feature_gate')
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useIsTailoredOnboardingM2ForQuickstartEnabled()
		: false;
	const addFlag = useCallback(
		// @ts-expect-error - TS7031 - Binding element 'appearance' implicitly has an 'any' type.
		({ appearance, ...flagProps }) => {
			if (appearance === 'success' || appearance === 'normal') {
				// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
				showFlag((serviceProps) => <SuccessFlag {...flagProps} {...serviceProps} />);
			}

			if (appearance === 'warning') {
				// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
				showFlag((serviceProps) => <WarningFlag {...flagProps} {...serviceProps} />);
			}

			if (appearance === 'error') {
				// @ts-expect-error Type error after Flag props have been fixed globally, to be fixed by owning team
				showFlag((serviceProps) => <ErrorFlag {...flagProps} {...serviceProps} />);
			}
		},
		[showFlag],
	);
	// @ts-expect-error - TS7006 - Parameter 'url' implicitly has an 'any' type.
	const pushRoute = useCallback((url) => push(`${baseUrl}${url}`), [baseUrl, push]);
	const peopleMenuWrapperRef = useRegisterNudgeTarget<HTMLDivElement>(
		JWM_INVITE_TEAM_NUDGE_ID,
		true,
	).ref;

	const {
		data: { user },
	} = useCurrentUser();

	const teamCreateDialogProps = useMemo(() => {
		const dialogProps: Partial<TeamCreateDialogProps> = {
			currentUser: {
				id: user.accountId || '',
				fullName: user.userFullname,
				// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
				email: user.emailAddress !== undefined ? user.emailAddress : '',
				// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
				avatarUrl: user?.avatarUrls ? user.avatarUrls['16x16'] : '',
				nickname: user?.userFullname,
			},
			enableMembershipSettingsChoice: true,
		};
		dialogProps.onClose = () => {
			if (peopleMenuWrapperRef?.current) {
				requestAnimationFrame(() => {
					peopleMenuWrapperRef?.current?.querySelector('button')?.focus();
				});
			}
		};
		return dialogProps;
		// the peopleMenuWrapperRef doesn't change as the condition if FG is static
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [user]);

	useEffect(() => {
		// close menu when transitioning to other pages.
		if (!fg('dont-auto-close-people-menu')) {
			closeMenu();
		}
		// close menu on unmount
		return () => {
			if (!fg('dont-auto-close-people-menu')) {
				closeMenu();
			}
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [route]);

	const { data: projectSidebarNavigationData } = useProjectSidebarNavigation();
	const subProduct = projectTypeToSubProduct(projectSidebarNavigationData?.projectType);

	const {
		cloudId,
		atlassianAccountId,
		appEditions: { software },
	} = useTenantContext();

	const { data: orgId } = useOrgId();

	const invitePeopleExperimentalFeatures: InvitePeopleProps['experimentalFeatures'] = useMemo(
		() => ({
			inviteThirdPartyTeams: VARIATION,
			coreInvitesDriveMultiProductInvites: fg('core_invites_drive_multi_product_invites_gate'),
		}),
		[],
	);

	// eslint-disable-next-line jira/ff/unsafe-no-exposure
	const [expConfig] = UNSAFE_noExposureExp('core_invites_drive_multi_product_invites');
	const isDriveMultiProductInviteEnabled = expConfig.get('cohort', NOT_ENROLLED) === VARIATION;

	// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
	const continueUrl = window.location.href;

	const entryPoint = useEntryPoint(inviteBusinessTeamsModalEntryPoint, {});

	const [{ traits }] = usePersonalizationProvider();
	const firstActivationDateMs = useFirstActivationDateMs();
	const storedValue = localStorage.get(STORAGE_KEY);
	const isSoftwareProject = subProduct === SOFTWARE_PROJECT;
	const isValidAppEdition = software === FREE_EDITION || software === STANDARD_EDITION;
	const isValidSiteAge =
		firstActivationDateMs && differenceInDays(new Date(), firstActivationDateMs) > 28;
	const hasDismissedInviteBefore =
		storedValue || traits.some((trait) => trait.name === SITE_USER_TRAIT_HAS_VIEW_EXPERIMENT);

	const showModal = Boolean(
		isSoftwareProject &&
			isValidAppEdition &&
			isValidSiteAge &&
			!hasDismissedInviteBefore &&
			!isDriveMultiProductInviteEnabled,
	);

	const { openModal } = useInvitePeopleModal({
		continueUrl,
		enableThirdParty: true,
		experimentalFeatures: invitePeopleExperimentalFeatures,
		displayInfiSpotlight: true,
		orgId,
		resourceAri: `ari:cloud:jira::site/${cloudId}`,
		showFlag: addFlag,
		source: 'peopleMenu',
		subProduct,
		experimental_applicationEdition: software,
		navigation: { directoryContextEntryPoint: '/jira' },
		onSubmit: async (data: OnSubmitData) => {
			const { inviteUsersResponse } = await data.defaultOnSubmit();
			// Handle invite business team modal experiment
			if (showModal) {
				const invitedJswUsers = getInvitedJswUsers(inviteUsersResponse);
				const invitedJswUserCount = invitedJswUsers.length;
				// make sure we only display our modal when invites are JSW
				if (invitedJswUserCount > 0) {
					setInviteBusinessTeamsModalData({
						isOpen: true,
						userCounts: invitedJswUserCount,
					});
					// Make sure user only see the dialog once, and avoid loading traits everytime when user invites a person
					localStorage.set(STORAGE_KEY, String(new Date()));
					entryPoint.entryPointActions.load();
				}
			}
		},
		// this is used to handle `add more people` button clicked, we need to hide invite business team modal
		...(showModal
			? {
					experimental_onReopenHandler: () => {
						openModal();
						setInviteBusinessTeamsModalData({
							isOpen: false,
							userCounts: 0,
						});
					},
				}
			: {}),
	});
	const invitePeopleHandlers = useMemo(
		() => ({
			handleClick: openModal,
		}),
		[openModal],
	);

	if (!atlassianAccountId) {
		return null;
	}

	return (
		<JSErrorBoundary
			id={MENU_ID.PEOPLE}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="navigation"
		>
			<PeopleMenuWrapper
				ref={peopleMenuWrapperRef}
				data-testid={`menu-${NAVIGATION_ITEM_ID.PEOPLE}-wrapper`}
			>
				{isEnabledForTailoredOnboardingM2 && <InviteTeamNudgeAsync />}
				{
					<PeopleMenu
						shouldRenderToParent
						product="jira"
						cloudId={cloudId}
						userId={atlassianAccountId}
						isBrowseUsersEnabled={
							fg('dont-auto-close-people-menu')
								? checkUsersGroupPermissionNext
								: checkUsersGroupPermission
						}
						// allow PeopleMenu to do transitions to in-product People Directory
						pushRoute={pushRoute}
						isHighlighted={isHighlighted}
						// @ts-expect-error - TS2322 - Type '({ appearance, ...flagProps }: { [x: string]: any; appearance: any; }) => void' is not assignable to type '(flag: Flag) => void'.
						addFlag={addFlag}
						onClick={triggerAnalytics}
						// manage open state
						isOpen={isMenuOpen}
						onOpen={openMenu}
						onClose={isEventFromSpotlightTour ? noop : closeMenu}
						customChevronIcon={<ChevronRightIcon />}
						enablePreFetchingByHovering={false}
						subProduct={subProduct}
						isSSR={__SERVER__}
						orgId={orgId ?? undefined}
						teamCreateDialogProps={teamCreateDialogProps}
						invitePeopleHandlers={invitePeopleHandlers}
						isSectionList
					/>
				}
			</PeopleMenuWrapper>
			{inviteBusinessTeamsModalData.isOpen && (
				<ModalEntryPointContainer
					entryPointReferenceSubject={entryPoint.entryPointReferenceSubject}
					id="invite-business-teams-modal"
					packageName="jira-business.invite-business-teams"
					teamName="jira-werewolves"
					runtimeProps={{
						userCounts: inviteBusinessTeamsModalData.userCounts,
						onClose: () => {
							setInviteBusinessTeamsModalData({
								isOpen: false,
								userCounts: 0,
							});
						},
					}}
					entryPointActions={entryPoint.entryPointActions}
				/>
			)}
		</JSErrorBoundary>
	);
};

const PeopleWithAuth = () => {
	const isAnonymous = useIsAnonymous();
	const { isVisible } = useOverflowStatus();
	const [{ hasOpened: shouldShowNudge }] = useHasOpened();

	if (isAnonymous) return null;

	if (!__SERVER__) {
		return (
			<JSErrorBoundary
				id="navigation.people-menu.with-nudgetooltip"
				packageName={PACKAGE_NAME}
				fallback="unmount"
				teamName="navigation"
			>
				{shouldShowNudge ? (
					<NavigationPeopleNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
						<FullHeightTextColorWrapper>
							<InvitePeopleModalContextProvider>
								<PeopleMenuDropdown />
							</InvitePeopleModalContextProvider>
						</FullHeightTextColorWrapper>
					</NavigationPeopleNavItemWrapperNudgeAsync>
				) : (
					<FullHeightTextColorWrapper>
						<InvitePeopleModalContextProvider>
							<PeopleMenuDropdown />
						</InvitePeopleModalContextProvider>
					</FullHeightTextColorWrapper>
				)}
			</JSErrorBoundary>
		);
	}

	return <PeopleMenuDropdown />;
};

export const People = () => (
	<TopLevelErrorBoundary id={MENU_ID.PEOPLE}>
		<PeopleWithAuth />
	</TopLevelErrorBoundary>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const PeopleMenuWrapper = styled.div({
	display: 'flex',
	height: '100%',
	flexGrow: 1,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > div': {
		flexGrow: 1,
	},
});
