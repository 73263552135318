import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { CrossJoinTargetProduct } from '../../common/types.tsx';
import type { AccessRequestCapabilityResponse } from './types.tsx';

export const fetchIsRequestAccessCapable = async (
	product: CrossJoinTargetProduct,
	cloudId: string,
): Promise<boolean> => {
	const resource = `ari:cloud:${product}::site/${cloudId}`;
	const params = new URLSearchParams({
		resource,
	});
	const URL = `/gateway/api/invitations/v1/access-requests/capabilities?${params.toString()}`;

	const response = await fetchJson<AccessRequestCapabilityResponse>(URL, {
		credentials: 'include',
	});
	const requestCapabilities = response.results[resource];
	return requestCapabilities === 'DIRECT_ACCESS' || requestCapabilities === 'REQUEST_ACCESS';
};

const CONFLUENCE_RA_CAPABILITIES_CACHE_KEY = 'cross.join.confluence-capabilities.cache.key';
// This is set to 1 day only as capabilties has many more states e.g PENDING_REQUEST_EXISTS, APPROVED_REQUEST_EXISTS etc
// We expect capabilities to change more often than product access
const CONFLUENCE_RA_CAPABILITIES_CACHE_KEY_EXPIRY_MS = 24 * 60 * 60 * 1000 * 1;
const crossJoinApiCache = createLocalExpirableStorageProvider(
	'cross-join-nudge-conf-capabilities-api-cache',
);

export const fetchCachedIsConfluenceRACapable = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	cloudId: string,
	touchpoint: string,
): Promise<boolean | null> => {
	let isRACapable = crossJoinApiCache.get(CONFLUENCE_RA_CAPABILITIES_CACHE_KEY);

	if (isRACapable === undefined) {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'capabilitiesCheck cacheMiss', {
			touchpoint,
		});
		try {
			isRACapable = await fetchIsRequestAccessCapable('confluence', cloudId);
		} catch (err) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'capabilitiesCheck fetchError', {
				touchpoint,
				err,
				sendToPrivacyUnsafeSplunk: true,
				skipSentry: true,
			});
			return null;
		}
		setIsRACapableCache(createAnalyticsEvent, isRACapable, touchpoint);
		return isRACapable;
	}
	fireOperationalAnalytics(createAnalyticsEvent({}), 'capabilitiesCheck cacheHit', {
		touchpoint,
	});
	return isRACapable;
};

export const setIsRACapableCache = (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	value: boolean,
	touchpoint: string,
) => {
	try {
		crossJoinApiCache.set(
			CONFLUENCE_RA_CAPABILITIES_CACHE_KEY,
			value,
			Date.now() + CONFLUENCE_RA_CAPABILITIES_CACHE_KEY_EXPIRY_MS,
		);
	} catch (err) {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'capabilitiesCheck setError', {
			touchpoint,
			err,
			skipSentry: true,
		});
	}
};

export const resetRACapableCache = () => {
	crossJoinApiCache.remove(CONFLUENCE_RA_CAPABILITIES_CACHE_KEY);
};
