import { expVal } from '@atlassian/jira-feature-experiments';
import { componentWithCondition } from '@atlassian/jira-feature-flagging-utils';
import TopNavAsync from './async.tsx';
import { AtlaskitAtlassianNavigationNav4 as TopNav } from './ui/atlaskit-atlassian-navigation-nav4/AtlaskitAtlassianNavigationNav4.tsx';

export const TopNav4 = componentWithCondition(
	() =>
		// eslint-disable-next-line jira/ff/inline-expVal-params -- ESLint rule doesn't understand the array type
		expVal<Array<string>>('jira_nav4_hot_113350_changes', 'keys', []).includes(
			'blu-5991-sync-load-nav4-sidebar',
		),
	TopNav,
	TopNavAsync,
);
