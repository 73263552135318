/**
 * @generated SignedSource<<92441afb5950f2741fbfc5e08443baee>>
 * @relayHash 138f5ad34e051899c79508841004f1bd
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID d3d83bfead3670f69acbd2f39fde1fbbf24445274ef86ef15f91404baac88088

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery } from './uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery.graphql';

const node: PreloadableConcreteRequest<uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "d3d83bfead3670f69acbd2f39fde1fbbf24445274ef86ef15f91404baac88088",
    "metadata": {},
    "name": "uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
