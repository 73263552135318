import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	AccessRequestResponse,
	AccessRequestType,
	CrossJoinIntegraton,
	CrossJoinTargetProduct,
} from '../../common/types.tsx';
import { fireExperimentError, type CreateAnalyticsEvent } from '../analytics/index.tsx';

const URL = '/gateway/api/invitations/v1/access-requests/request';

export const requestAccess = async (
	product: CrossJoinTargetProduct,
	cloudId: string,
	userId: string,
	integration: CrossJoinIntegraton,
	createAnalyticsEvent: CreateAnalyticsEvent,
): Promise<AccessRequestType> => {
	const resource = `ari:cloud:${product}::site/${cloudId}`;

	try {
		const response = await fetchJson<AccessRequestResponse>(URL, {
			method: 'POST',
			credentials: 'include',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				resource,
			}),
		});
		const { results } = response;
		const result = results[resource];
		const analyticsAttributes = {
			userId,
			products: [product],
			inviteResult: [
				{
					resourceAri: resource,
					status: result,
				},
			],
			integration,
		};
		fireTrackAnalytics(
			createAnalyticsEvent({}),
			'productAccess requested',
			userId,
			analyticsAttributes,
		);
		return result;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireExperimentError(integration, error);
		throw new Error(`${integration} request access error`);
	}
};
