import React, { useCallback, useEffect, useMemo } from 'react';
import { useOverflowStatus } from '@atlaskit/atlassian-navigation';
import { useAddon } from '@atlassian/jira-addon-store/src/index.tsx';
import { NAVIGATION_TOP_DROPDOWN_MARKS } from '@atlassian/jira-atlassian-navigation-performance-metrics/src/index.tsx';
import { useExperienceStart } from '@atlassian/jira-experience-tracker/src/ui/experience-start/index.tsx';
import { ff } from '@atlassian/jira-feature-flagging';
import { useForgeGlobalItems } from '@atlassian/jira-forge-global-items-store/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { NavigationAppsNavItemWrapperNudgeAsync } from '@atlassian/jira-software-onboarding-nudges--next/src/ui/navigation-apps/async.tsx';
import { useHasOpened } from '@atlassian/jira-software-onboarding-quickstart-has-opened/src/services/has-opened/index.tsx';
import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import { EXPERIENCE_NAVIGATION_TOP_MENU, NAVIGATION_ITEM_ID } from '../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import { FullHeightTextColorWrapper } from '../../common/ui/nudges/styled.tsx';
import { Popup } from '../../common/ui/popup/main.tsx';
import { usePersonalisedAppsTeamType } from '../../controllers/personalised-apps-team-type/index.tsx';
import { AsyncMenu } from './menu/async.tsx';
import messages from './messages.tsx';

const AddonsView = () => {
	const { key: projectIdOrKey } = useProjectContext();
	const [, { load: loadItem, setContext }] = useAddon();
	const [, { load: loadForgeItem }] = useForgeGlobalItems();

	useEffect(() => {
		setContext({ projectIdOrKey });
	}, [projectIdOrKey, setContext]);

	const [
		{ hasSuccessOnce: hasSuccessOnceUserSegmentation },
		{ loadPersonalisedAppsUserSegmentation },
	] = usePersonalisedAppsTeamType();

	const { activationId, cloudId, isAnonymous } = useTenantContext();

	const { formatMessage, locale } = useIntl();
	const onStart = useExperienceStart({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
		experienceId: NAVIGATION_ITEM_ID.ADDONS,
		analyticsSource: 'atlassian-navigation',
	});

	const onBeforeMenuOpen = useCallback(() => {
		onStart();

		loadItem();
		loadForgeItem({ cloudId, activationId, isAnonymous, locale });
		!hasSuccessOnceUserSegmentation && loadPersonalisedAppsUserSegmentation();

		NAVIGATION_TOP_DROPDOWN_MARKS.ADDONS.start();
	}, [
		onStart,
		loadItem,
		hasSuccessOnceUserSegmentation,
		loadPersonalisedAppsUserSegmentation,
		loadForgeItem,
		cloudId,
		isAnonymous,
		activationId,
		locale,
	]);

	const attributes = useMemo(
		() => ({
			isEmbeddedMarketplaceEnabledForEndUsers: ff('enduser-emcee-discover'),
			isEmbeddedMarketplaceSpaEnabled: ff('marketplace.emcee.native.spa.enable'),
		}),
		[],
	);

	return (
		<ContextualAnalyticsData attributes={attributes}>
			<Popup
				menuId={MENU_ID.ADDONS}
				navigationItemId={NAVIGATION_ITEM_ID.ADDONS}
				Menu={AsyncMenu}
				onBeforeMenuOpen={onBeforeMenuOpen}
				label={formatMessage(messages.apps)}
			>
				{formatMessage(messages.apps)}
			</Popup>
		</ContextualAnalyticsData>
	);
};

/**
 * Nav3 component
 */
export const Addons = () => {
	const { isVisible } = useOverflowStatus();
	const [{ hasOpened: shouldShowNudge }] = useHasOpened();

	return (
		<TopLevelErrorBoundary id={MENU_ID.ADDONS}>
			{!__SERVER__ && shouldShowNudge ? (
				<NavigationAppsNavItemWrapperNudgeAsync isInsideMenu={!isVisible}>
					<FullHeightTextColorWrapper>
						<AddonsView />
					</FullHeightTextColorWrapper>
				</NavigationAppsNavItemWrapperNudgeAsync>
			) : (
				<AddonsView />
			)}
		</TopLevelErrorBoundary>
	);
};
