export type CrossJoinTargetProduct = 'confluence' | 'jira-product-discovery';

export const ProductDisplayNames = {
	confluence: 'Confluence',
	'jira-product-discovery': 'Jira Product Discovery',
};

export type CrossJoinIntegraton =
	| 'crossJoinNudgesConfluence'
	| 'crossJoinNudgesJiraToJpd'
	| 'crossJoinNudgesYourWork';

export type AccessRequestType =
	| 'ACCESS_GRANTED'
	| 'PENDING_REQUEST_CREATED'
	| 'ACCESS_EXISTS'
	| 'VERIFICATION_REQUIRED'
	| 'PENDING_REQUEST_EXISTS'
	| 'DENIED_REQUEST_EXISTS'
	| 'APPROVED_REQUEST_EXISTS'
	| 'FORBIDDEN';

export type AccessRequestResponse = {
	results: {
		[resource: string]: AccessRequestType;
	};
};
