import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import Image from '@atlaskit/image';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { ff } from '@atlassian/jira-feature-flagging';
import TeamsIcon from '@atlassian/jira-icons/src/ui/ms-teams-logomark/index.tsx';
import SlackIcon from '@atlassian/jira-icons/src/ui/slack-logomark/index.tsx';
import slackIconOld from './assets/slack.svg';
import teamsIconOld from './assets/teams.svg';

interface ConnectButtonProps {
	onClick: () => void;
}

export const MicrosoftTeamsConnectButton = ({ onClick }: ConnectButtonProps): ReactNode => {
	return (
		<IntegrationButton
			testId="third-party-nudge.ui.third-party-nudge-flag.connect-button.both-find-out-more-button"
			onClick={onClick}
		>
			<IntegrationButtonContent>
				{ff('groot_jfe_assets_consolidation') ? (
					<Box xcss={teamsLogoWrapperStyles}>
						<TeamsIcon secondaryColor="currentColor" label="Teams icon" />
					</Box>
				) : (
					<Image src={teamsIconOld} alt="Teams icon" width={16} />
				)}
				<IntegrationName>Microsoft Teams</IntegrationName>
			</IntegrationButtonContent>
		</IntegrationButton>
	);
};

export const SlackConnectButton = ({ onClick }: ConnectButtonProps): ReactNode => {
	return (
		<IntegrationButton
			testId="third-party-nudge.ui.third-party-nudge-flag.connect-button.both-connect-slack-button"
			onClick={onClick}
		>
			<IntegrationButtonContent>
				{ff('groot_jfe_assets_consolidation') ? (
					<Box xcss={SlackLogoWrapperStyles}>
						<SlackIcon label="Slack icon" />
					</Box>
				) : (
					<Image src={slackIconOld} alt="Slack icon" width={16} />
				)}
				<IntegrationName>Slack</IntegrationName>
			</IntegrationButtonContent>
		</IntegrationButton>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IntegrationName = styled.span({
	font: token('font.body.UNSAFE_small'),
	fontWeight: token('font.weight.semibold'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IntegrationButtonContent = styled.div({
	display: 'flex',
	flexDirection: 'row',
	justifyContent: 'center',
	alignItems: 'center',
	gap: token('space.100', '8px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const IntegrationButton = styled(Button)({
	flex: 1,
});

const teamsLogoWrapperStyles = xcss({
	width: '16px',
	height: '20px',
	display: 'flex',
	marginLeft: 'space.negative.050',
	marginRight: 'space.050',
});

const SlackLogoWrapperStyles = xcss({
	width: '20px',
	height: '20px',
	display: 'flex',
});
