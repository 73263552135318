/** @jsx jsx */
import { jsx } from '@atlaskit/css';
import AkAppIcon from '@atlaskit/icon/core/migration/app--addon';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { IconCustom } from '@atlassian/jira-icon-custom/src/index.tsx';

export const ForgeIcon = ({ url }: { url?: string | null }) => {
	return url && String(url).trim().length > 0 ? (
		<IconCustom shouldChangeFillColor={false} url={url} />
	) : (
		<AkAppIcon
			color={token('color.icon')}
			spacing="spacious"
			LEGACY_primaryColor={token('color.icon', colors.N500)}
			label=""
		/>
	);
};
