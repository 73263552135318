import React from 'react';

import { IconButton } from '@atlaskit/button/new';
import Drawer from '@atlaskit/drawer';
import ArrowLeft from '@atlaskit/icon/core/migration/arrow-left';
import { Box, xcss } from '@atlaskit/primitives';
import CommerceExperience from '@atlassian/commerce-experience-sdk-react';
import { type Action, createStore, createHook } from '@atlassian/react-sweet-state';

type State =
	| {
			isDrawerOpen: true;
			url: string;
	  }
	| { isDrawerOpen: false; url: null };

const initialState: State = {
	isDrawerOpen: false,
	url: null,
};

type Actions = typeof actions;

const actions = {
	openDrawer:
		(url: string): Action<State> =>
		({ setState }) => {
			setState({ isDrawerOpen: true, url });
		},
	closeDrawer:
		(): Action<State> =>
		({ setState }) => {
			setState({ isDrawerOpen: false, url: null });
		},
};

const Store = createStore<State, Actions>({
	initialState,
	actions,
	name: 'commerce-experience-drawer',
});

export const useCommerceExperienceDrawer = createHook(Store);

export const CommerceExperienceDrawer = () => {
	const [{ isDrawerOpen, url }, { closeDrawer }] = useCommerceExperienceDrawer();

	return (
		<Drawer
			testId="commerce-experience-drawer.ui.drawer"
			isOpen={isDrawerOpen}
			onClose={closeDrawer}
			width="wide"
			// We add back in the back button manually to make it a "floating" button
			// Implementation from https://bitbucket.org/atlassian/atlassian-frontend-mirror/src/master/design-system/drawer/src/components/primitives/index.tsx
			// eslint-disable-next-line @atlaskit/design-system/no-deprecated-apis -- The sidebar needs to be overridden to not take up space on the left
			overrides={{
				Content: {
					component: (props) => <Box xcss={contentContainerStyles}>{props.children}</Box>,
				},
				Sidebar: {
					component: (props) => (
						<Box xcss={buttonContainerStyles}>
							<IconButton
								onClick={closeDrawer}
								label={props.label}
								shape="circle"
								icon={ArrowLeft}
								appearance="subtle"
							/>
						</Box>
					),
				},
			}}
		>
			{url ? <CommerceExperience url={url} onComplete={closeDrawer} onError={closeDrawer} /> : null}
		</Drawer>
	);
};

const buttonContainerStyles = xcss({
	position: 'absolute',
	top: 'space.100',
	left: 'space.100',
});

const contentContainerStyles = xcss({
	width: '100%',
});
