import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	slackIntegrationName: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.slack-integration-name',
		defaultMessage: 'Jira Cloud',
		description: 'Integration name of Slack app in the nudge.',
	},
	teamsNudgeTitle: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.teams-nudge-title',
		defaultMessage: 'Mike commented on an issue',
		description: 'Title of the Microsoft Teams in the nudge.',
	},
	slackNudgeTitle: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.slack-nudge-title',
		defaultMessage: '<tag>@mike</tag> assigned an issue to you',
		description: 'Title of the Slack example in the nudge.',
	},
	bothNudgeTitle: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.both-nudge-title',
		defaultMessage: '<tag>@mike</tag> mentioned you in a comment',
		description: 'Title of the product-agnostic in the nudge.',
	},
	nudgeCommentButton: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.nudge-comment-button',
		defaultMessage: 'Comment',
		description: 'Button to add a comment in the Slack and Microsoft in the nudge.',
	},
	nudgeEditIssueButton: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.nudge-edit-issue-button',
		defaultMessage: 'Edit issue',
		description: 'Button to edit issue in the Slack and Microsoft in the nudge.',
	},
	nudgeOpenInJiraButton: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.nudge-open-in-jira-button',
		defaultMessage: 'Open in Jira',
		description: 'Button to open the issue in Jira in the Slack and Microsoft in the nudge.',
	},
	teamsNudgeTitleIssueTermRefresh: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.teams-nudge-title-issue-term-refresh',
		defaultMessage: 'Mike commented on an issue',
		description: 'Title of the Microsoft Teams in the nudge.',
	},
	slackNudgeTitleIssueTermRefresh: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.slack-nudge-title-issue-term-refresh',
		defaultMessage: '<tag>@mike</tag> assigned an issue to you',
		description: 'Title of the Slack example in the nudge.',
	},
	nudgeEditIssueButtonIssueTermRefresh: {
		id: 'third-party-nudge.third-party-nudge-flag.splashes.notification-bell-splashes.nudge-edit-issue-button-issue-term-refresh',
		defaultMessage: 'Edit issue',
		description: 'Button to edit issue in the Slack and Microsoft in the nudge.',
	},
});
