import { useMemo } from 'react';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { getUserProperty, setUserProperty } from '../common/user-properties/index.tsx';

type NudgeState = 'NONE' | 'DISMISSED';

type NudgeStateKey =
	| 'CROSS_JOIN_NUDGES_CONFLUENCE_V2A'
	| 'CROSS_JOIN_NUDGES_YOUR_WORK'
	| 'CROSS_JOIN_NUDGES_JIRA_TO_JPD';

const getNudgeState = async (key: NudgeStateKey, accountId: string | null): Promise<NudgeState> => {
	if (!accountId) return 'NONE';

	const nudgeState = await getUserProperty<NudgeState>({
		accountId,
		propertyKey: key,
		defaultValue: 'NONE',
	});

	return nudgeState.value;
};

const setNudgeDismissState = async (key: NudgeStateKey, accountId: string | null) => {
	if (!accountId) return;

	await setUserProperty<NudgeState>({
		accountId,
		propertyKey: key,
		propertyValue: 'DISMISSED',
	});
};

const useNudgeState = (key: NudgeStateKey) => {
	const accountId = useAccountId();
	return useMemo(
		() => ({
			getNudgeState: () => getNudgeState(key, accountId),
			setNudgeDismissed: () => setNudgeDismissState(key, accountId),
		}),
		[accountId, key],
	);
};

export const useNudgeStateV2a = () => useNudgeState('CROSS_JOIN_NUDGES_CONFLUENCE_V2A');
export const useNudgeStateYourWork = () => useNudgeState('CROSS_JOIN_NUDGES_YOUR_WORK');
export const useNudgeStateJiraToJpd = () => useNudgeState('CROSS_JOIN_NUDGES_JIRA_TO_JPD');
