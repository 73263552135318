import {
	CORE_PROJECT,
	PRODUCT_DISCOVERY_PROJECT,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	JIRA_PRODUCT_DISCOVERY,
	JIRA_SERVICE_DESK,
	JIRA_SOFTWARE,
} from '@atlassian/jira-shared-types/src/application.tsx';
import type { SupportedJiraApplicationKey, SupportedProjectType } from '../../common/types.tsx';

/**
 * The reson this method exits instead of using the one at '@atlassian/jira-shared-types/src/application.tsx' is because:
 *  - We want to change the return type to SupportedJiraApplicationKey
 *  - We are considering CORE_PROJECT as a software project
 * @param projectType
 * @returns
 */
export const getJiraApplicationKeyForProject = (
	projectType: SupportedProjectType,
): SupportedJiraApplicationKey => {
	switch (projectType) {
		case CORE_PROJECT:
		case SOFTWARE_PROJECT:
			return JIRA_SOFTWARE;
		case SERVICE_DESK_PROJECT:
			return JIRA_SERVICE_DESK;
		case PRODUCT_DISCOVERY_PROJECT:
			return JIRA_PRODUCT_DISCOVERY;
		default:
			throw new Error(`Unrecognised project type: ${projectType}`);
	}
};
