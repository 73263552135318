/**
 * @generated SignedSource<<6c2d65ef3150f31fab40cc3f684ffb50>>
 * @relayHash f9ef78d6baf6109d89a706902cd28882
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID f9e628ae629c91fc1ba80323089325dd02c1b03c0377ab5c81503e73202e3f46

import type { PreloadableConcreteRequest } from 'relay-runtime';
import type { wrapperEditionAwarenessModalWrapperQuery } from './wrapperEditionAwarenessModalWrapperQuery.graphql';

const node: PreloadableConcreteRequest<wrapperEditionAwarenessModalWrapperQuery> = {
  "kind": "PreloadableConcreteRequest",
  "params": {
    "id": "f9e628ae629c91fc1ba80323089325dd02c1b03c0377ab5c81503e73202e3f46",
    "metadata": {},
    "name": "wrapperEditionAwarenessModalWrapperQuery",
    "operationKind": "query",
    "text": null
  }
};

export default node;
