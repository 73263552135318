/** @jsx jsx */
import React, { useMemo, useCallback } from 'react';
import { css, jsx } from '@compiled/react';
import InfoIcon from '@atlaskit/icon/glyph/info';
import { Inline, Stack, Pressable, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { type FlagComponentProps, InfoFlag } from '@atlassian/jira-flags';
import { useIntl } from '@atlassian/jira-intl';
import { FlagCompatibleAvatarGroup } from '../../common/ui/flag-compatible-avatar-group/index.tsx';
import { messages } from '../../common/ui/request-access-prompt-flag/messages.tsx';
import { noop } from '../../common/utils.tsx';
import { useDynamicMessaging, type EntityId } from '../../services/dynamic-messaging/index.tsx';
import type { Collaborators } from '../../services/get-collaborators/types.tsx';

export type Props = {
	id: FlagComponentProps['id'];
	onDismiss: FlagComponentProps['onDismissed'];
	users: Collaborators;
	onOptoutAndDismiss: () => unknown;
	onSubmit: () => Promise<void>;
	onSnoozeAndDismiss: () => unknown;
	isContextualBandit?: boolean;
	entityId?: EntityId;
	featureVisitedCount: number;
};

type InfoFlagProps = React.ComponentProps<typeof InfoFlag>;

export const RequestAccessPromptFlag = ({
	users,
	onSubmit,
	onDismiss,
	onOptoutAndDismiss,
	onSnoozeAndDismiss,
	isContextualBandit = false,
	entityId = 'default',
	featureVisitedCount,
	...props
}: Props) => {
	const handleOnSubmit = useCallback(async () => {
		await onSubmit();
	}, [onSubmit]);
	const { formatMessage } = useIntl();
	const dynamicMessages = useDynamicMessaging(isContextualBandit, entityId) || messages;
	const customFlagFooterProps = useMemo(
		(): Pick<InfoFlagProps, 'actions' | 'linkComponent'> => ({
			// Workaround to achieve primary button + avatars in the footer.
			//
			// How this works:
			// We pass a dummy `action` that gets displayed in the Flag's footer
			// by the custom `linkComponent`. The component displays our actual footer.
			// This way, we don't occupy the limited space inside `description`.
			//
			// Why not use `actions` normally?
			// Because our Avatar Group is not an action, and even our Submit button
			// should be bold & blue (appearance="primary") which is not supported
			// by the AK Flag.
			//
			// Why not put this in `description`?
			// Because that one has a max height equivalent to 5 lines of text,
			// and our footer is taller than 2, causing an unwanted scrollbar
			// to show.
			actions: [
				{
					content: '',
					onClick: noop,
				},
			],
			linkComponent: () => {
				let secondETA = null;

				if (featureVisitedCount === 1) {
					secondETA = (
						<Pressable xcss={linkButtonStyles} onClick={onSnoozeAndDismiss}>
							{formatMessage(messages.remindMeLaterButton)}
						</Pressable>
					);
				} else if (featureVisitedCount > 1) {
					secondETA = (
						<Pressable xcss={linkButtonStyles} onClick={onOptoutAndDismiss}>
							{formatMessage(messages.notInterestedButton)}
						</Pressable>
					);
				}

				return (
					<Inline alignInline="start" grow="fill" alignBlock="center">
						<Pressable xcss={linkButtonStyles} onClick={handleOnSubmit}>
							{formatMessage(dynamicMessages.joinButton)}
						</Pressable>
						{!!secondETA && <span css={linkButtonSeparatorStyles} />}
						{secondETA}
					</Inline>
				);
			},
		}),
		[
			featureVisitedCount,
			formatMessage,
			handleOnSubmit,
			dynamicMessages.joinButton,
			onSnoozeAndDismiss,
			onOptoutAndDismiss,
		],
	);

	const customIcon = useMemo(() => {
		if (isContextualBandit) {
			return <InfoIcon primaryColor={token('color.icon.information', '#1D7AFC')} label="" />;
		}
		return (
			<FlagCompatibleAvatarGroup
				alignment="vertical"
				maxAvatarCount={2}
				tooltipPosition="bottom"
				data={users.map((user) => ({
					name: user.name,
					src: user.avatar,
					appearance: 'circle',
					size: 'medium',
				}))}
				boundariesElement="scrollParent"
			/>
		);
	}, [isContextualBandit, users]);

	const infoFlagTitle = isContextualBandit
		? formatMessage(dynamicMessages.title)
		: formatMessage(messages.urgencyTitle, { viewCount: featureVisitedCount });

	const FLAG_MESSAGE_TYPE = 'marketing';

	return (
		<InfoFlag
			{...props}
			{...customFlagFooterProps}
			onDismissed={featureVisitedCount === 0 ? onDismiss : onSnoozeAndDismiss}
			title={infoFlagTitle}
			description={
				<Stack space="space.100">
					<p css={mainContentContainerStyles}>{formatMessage(dynamicMessages.description)}</p>
				</Stack>
			}
			icon={customIcon}
			messageType={FLAG_MESSAGE_TYPE}
			messageId="cross-join-nudges.ui.request-access-prompt-flag.info-flag.request-access-prompt-flag"
		/>
	);
};

const linkButtonStyles = xcss({
	maxWidth: '150',
	maxHeight: '3lh',
	overflow: 'hidden',
	color: 'color.link',
	backgroundColor: 'color.background.neutral.subtle',
	borderWidth: '0',
	font: token('font.body'),
	fontWeight: token('font.weight.medium'),
	cursor: 'pointer',
	padding: '0',
	':hover': {
		textDecoration: 'underline',
	},
});

const linkButtonSeparatorStyles = css({
	'&::before': {
		content: '·',
		marginLeft: token('space.150', '12px'),
		marginRight: token('space.150', '12px'),
	},
});

const mainContentContainerStyles = css({
	display: '-webkit-box',
	'-webkit-line-clamp': '3',
	'-webkit-box-orient': 'vertical',
	overflow: 'visible',
	margin: '0',
	marginBottom: token('space.100', '8px'),
});
