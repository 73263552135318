import { useTenantContext } from '@atlassian/jira-tenant-context-controller/src/components/tenant-context/index.tsx';
import type { ProjectType } from '@atlassian/jira-common-constants/src/index.tsx';
import { projectTypeToCanonicalId } from '@atlassian/jira-common-constants/src/project-types.tsx';

/**
 * Determines whether the current product type is using CCP for its billing system.
 * Returns true if using CCP.
 * JSM/JPD experiment by Transmuters
 * Contact: Matthew Yeow
 */
export function useIsProductUsingCcp(projectType: ProjectType) {
	const { productEntitlementDetails } = useTenantContext();
	const productKey = projectTypeToCanonicalId(projectType);

	const productEntitlement = productEntitlementDetails?.[productKey];

	return productEntitlement?.billingSourceSystem === 'CCP';
}
