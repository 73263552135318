import React, { useCallback, useMemo } from 'react';
import { useFlagsService } from '@atlassian/jira-flags'; // ignore-for-ENGHEALTH-17759
import { withMetrics } from '@atlassian/jira-forge-ui-analytics/src/common/ui/index.tsx';
import { measureInitialPageLoadAsyncResourcesTiming } from '@atlassian/jira-forge-ui-analytics/src/common/utils/performance-analytics/main.tsx';
import { PERFORMANCE_KEYS } from '@atlassian/jira-forge-ui-analytics/src/constants.tsx';
import {
	CONTEXT_TOKEN_TYPE_JIRA,
	CUSTOM_FIELD_MODULE,
	ENTRY_POINT_EDIT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import {
	isCustomUIExtension,
	isNativeUiExtension,
} from '@atlassian/jira-forge-ui-utils-internal/src/utils/extension/index.tsx';
import type { ServiceResult } from '@atlassian/jira-forge-ui/src/services/custom-fields/save-field/types.tsx';
import type { CustomFieldEdit as CustomFieldEditType } from '@atlassian/jira-forge-ui/src/ui/components/custom-field/edit/index.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { StopSubmitPropagation } from '../../../common/ui/submit-stop-propogation-wrapper/index.tsx';
import { onCustomFieldSubmit } from '../../../common/utils/on-custom-field-submit/index.tsx';
import type { LazyComponentProps } from '../../../types.tsx';

export type Bridge = {
	submit: (opts: { data: ForgeCustomFieldValue }) => Promise<void>;
};

type EditProps = Flow.Diff<LazyComponentProps<typeof CustomFieldEditType>, Bridge> & {
	onConfirm: (newValue: ForgeCustomFieldValue) => void;
	onCancel: () => void;
	onSave: (value: ForgeCustomFieldValue) => Promise<ServiceResult>;
};

const getModule = () =>
	measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_END,
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-custom-field-edit" */ '@atlassian/jira-forge-ui/src/ui/components/custom-field/edit'
			),
	);

const getForgeExtensionModule = () =>
	measureInitialPageLoadAsyncResourcesTiming(
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_START,
		PERFORMANCE_KEYS.MARKS.ASYNC_FORGE_UI_CUSTOM_FIELD_EDIT_BUNDLE_END,
		() =>
			import(
				/* webpackChunkName: "async-forge-ui-custom-field-edit-extension" */ '@atlassian/jira-forge-ui/src/common/ui/extension/components/custom-field/edit'
			),
	);

export const LazyCustomFieldEditRenderer = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getModule().then((module) => module.CustomFieldEdit), { ssr: false }),
);

export const LazyCustomFieldEditExtension = withMetrics(
	// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
	lazyForPaint(() => getForgeExtensionModule().then((module) => module.CustomFieldEdit), {
		ssr: false,
	}),
);

export const LazyCustomFieldEdit = ({
	onSave,
	onCancel,
	onConfirm,
	onError,
	onRender,
	...restProps
}: EditProps) => {
	const { extension, extensionData, extensionPayload } = restProps;
	const { showFlag } = useFlagsService();

	const extensionDataValue = useMemo(() => {
		const preparedExtensionData =
			extensionData?.issue && extensionData?.project
				? extensionData
				: {
						fieldType: extensionData.fieldType,
						fieldId: extensionData.fieldId,
						fieldName: extensionData.fieldName,
						renderContext: extensionData.renderContext,
					};

		return {
			...preparedExtensionData,
			type: extension.type,
			entryPoint: ENTRY_POINT_EDIT,
		};
	}, [extension.type, extensionData]);

	const extraProps = useMemo(
		() => ({
			module: CUSTOM_FIELD_MODULE,
			entryPoint: ENTRY_POINT_EDIT,
			extensionData: extensionDataValue,
			extensionPayload,
			contextToken: CONTEXT_TOKEN_TYPE_JIRA,
		}),
		[extensionDataValue, extensionPayload],
	);

	const submit = useCallback(
		({ data: fieldValue }: { data: ForgeCustomFieldValue }): Promise<void> =>
			onCustomFieldSubmit(fieldValue, onSave, onConfirm, showFlag),
		// todo SMT-491 onConfirm, onSave (fieldId) was changing every time component tree was changed by Bento issue view.
		// root cause - Bento's connect-field is creating onConfirm function and fieldId every time. More in mentioned ticket
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[showFlag],
	);
	const close = onCancel;

	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	const bridge: any = useMemo(() => ({ submit, close }), [submit, close]);

	if (
		isNativeUiExtension(extension, ENTRY_POINT_EDIT) ||
		isCustomUIExtension(extension, ENTRY_POINT_EDIT)
	) {
		return (
			<StopSubmitPropagation>
				<Placeholder name="custom-field-edit" fallback={null}>
					<LazyCustomFieldEditExtension
						{...restProps}
						{...extraProps}
						onError={onError}
						onRender={onRender}
						onCancel={onCancel}
						submit={submit}
						bridge={bridge}
					/>
				</Placeholder>
			</StopSubmitPropagation>
		);
	}

	return (
		<StopSubmitPropagation>
			<Placeholder name="custom-field-edit" fallback={null}>
				<LazyCustomFieldEditRenderer
					{...restProps}
					{...extraProps}
					onError={onError}
					onRender={onRender}
					onCancel={onCancel}
					submit={submit}
					bridge={bridge}
				/>
			</Placeholder>
		</StopSubmitPropagation>
	);
};

export const AsyncCustomFieldEdit = LazyCustomFieldEdit;
