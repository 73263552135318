import React, { useCallback } from 'react';
import { dismissFlag as createDismissFlag, useFlagService } from '@atlassian/jira-flags';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import type { CrossJoinIntegraton } from '../../common/types.tsx';
import type { EntityId } from '../../services/dynamic-messaging/index.tsx';
import type { Collaborators } from '../../services/get-collaborators/types.tsx';
import { generateId } from '../../utils/index.tsx';
import { RequestAccessPromptFlag as RequestAccessPromptFlagJpd } from './flag-jpd.tsx';
import { RequestAccessPromptFlag as RequestAccessPromptFlagV2 } from './flag-v2a.tsx';

export const useRequestAccessPromptFlag = () => {
	const { showFlag, dismissFlag } = useFlagService();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const userId = useAccountId() || '';

	return useCallback(
		({
			users,
			onOptout,
			onSubmit,
			snoozeFor30Days,
			integration,
			isContextualBandit = false,
			entityId = 'default',
			sessionId = '',
			featureVisitedCount = 0,
		}: {
			users: Collaborators;
			onOptout: () => unknown;
			onSubmit: () => Promise<void>;
			snoozeFor30Days: () => unknown;
			integration: CrossJoinIntegraton;
			isContextualBandit?: boolean;
			entityId?: EntityId;
			sessionId?: string;
			featureVisitedCount?: number;
		}) => {
			const id = `request-access-${generateId()}`;

			const dismissTheFlag = () => dismissFlag(createDismissFlag(id));

			const eventAttributes = {
				collaborators: users.length,
				userId,
				isContextualBandit,
				entityId,
				sessionId,
				featureVisitedCount,
			};

			const submit = async () => {
				fireUIAnalytics(createAnalyticsEvent({}), 'button clicked', integration, eventAttributes);
				dismissTheFlag();
				await onSubmit();
			};

			const dismiss = () => {
				fireUIAnalytics(createAnalyticsEvent({}), 'flag dismissed', integration, eventAttributes);
				dismissTheFlag();
			};

			const dismissAndOptout = () => {
				dismiss();
				fireUIAnalytics(createAnalyticsEvent({}), 'flag optedOut', integration, eventAttributes);
				onOptout();
			};

			const dismissAndSnooze = () => {
				dismissTheFlag();
				fireUIAnalytics(createAnalyticsEvent({}), 'flag snoozed', integration, eventAttributes);
				snoozeFor30Days();
			};

			fireUIAnalytics(createAnalyticsEvent({}), 'nudge viewed', integration, eventAttributes);

			showFlag({
				id,
				render: (props) =>
					integration === 'crossJoinNudgesJiraToJpd' ? (
						<RequestAccessPromptFlagJpd
							{...props}
							id={id}
							users={users}
							onDismiss={dismiss}
							onSnoozeAndDismiss={dismissAndSnooze}
							onOptoutAndDismiss={dismissAndOptout}
							onSubmit={submit}
							featureVisitedCount={featureVisitedCount}
						/>
					) : (
						<RequestAccessPromptFlagV2
							{...props}
							id={id}
							users={users}
							onDismiss={dismiss}
							onSnoozeAndDismiss={dismissAndSnooze}
							onOptoutAndDismiss={dismissAndOptout}
							onSubmit={submit}
							isContextualBandit={isContextualBandit}
							entityId={entityId}
							featureVisitedCount={featureVisitedCount}
						/>
					),
			});
		},
		[createAnalyticsEvent, dismissFlag, showFlag, userId],
	);
};
