import { isValidationError } from '@atlassian/jira-fetch/src/utils/errors.tsx';
import type { ShowFlagFn } from '@atlassian/jira-flags/src/services/types'; // ignore-for-ENGHEALTH-17759
import type { ForgeCustomFieldValue } from '@atlassian/jira-forge-ui-types/src/common/types/contexts/custom-field.tsx';
import customFieldEditFailed from '@atlassian/jira-forge-ui/src/common/ui/flags/custom-field/edit-failed/index.tsx';
import type { ServiceResult } from '@atlassian/jira-forge-ui/src/services/custom-fields/save-field/types.tsx';

export const onCustomFieldSubmit = (
	fieldValue: ForgeCustomFieldValue,
	onSave: (value: ForgeCustomFieldValue) => Promise<ServiceResult>,
	onConfirm: (newValue: ForgeCustomFieldValue) => void,
	showFlag: ShowFlagFn,
) =>
	onSave(fieldValue)
		.then((response) => {
			if (response == null) return;

			// The error is handled in the catch block
			if ('error' in response) {
				if (response.error) {
					throw response.error;
				}
			}
			// There is some rendering issue so onConfirm gets a different reference every time,
			// we don't add it as a dependency of the effect and just call the most recent one
			else {
				onConfirm(response.data);
			}
		})
		.catch((error) => {
			showFlag(customFieldEditFailed(error));
			// Custom UI depends on this error to get know whether the form was successfully submitted or not
			if (isValidationError(error)) throw error;
			throw Error(`Unable to save custom field, error: ${error.message}`);
		});
