import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	sideNavToggleButtonCollapseLabel: {
		id: 'atlassian-navigation-nav4.atlaskit-atlassian-navigation-nav4.side-nav-toggle-button-collapse-label',
		defaultMessage: 'Collapse sidebar [',
		description:
			'Label to use for side nav toggle button when it will collapse the side bar when clicked. It ends with a bracket character that reflects the keyboard shortcut that will toggle the side bar.',
	},
	sideNavToggleButtonExpandLabel: {
		id: 'atlassian-navigation-nav4.atlaskit-atlassian-navigation-nav4.side-nav-toggle-button-expand-label',
		defaultMessage: 'Expand sidebar [',
		description:
			'Label to use for side nav toggle button when it will expand the side bar when clicked. It ends with a bracket character that reflects the keyboard shortcut that will toggle the side bar.',
	},
});
