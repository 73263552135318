import React from 'react';
import { Popup, type PopupProps } from '@atlaskit/popup';
import type { MaybeChoreographedComponentProps } from '@atlassian/jira-choreographer-services/src/types.tsx';
import { shouldBeChoreographed } from '@atlassian/jira-choreographer-services/src/utils.tsx';
import { ChoreographerPopup } from './choreographer-popup.tsx';

export type JiraPopupProps = PopupProps & MaybeChoreographedComponentProps;

export const JiraPopup = (props: JiraPopupProps) => {
	if (shouldBeChoreographed(props)) {
		const { messageType, ...rest } = props;
		return <ChoreographerPopup {...rest} />;
	}

	const { messageType, messageId, onMessageDisposition, ...rest } = props;
	return <Popup {...rest} />;
};
