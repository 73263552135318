import React, { useEffect, useMemo } from 'react';
import { styled } from '@compiled/react';
import { Section, SkeletonHeadingItem, SkeletonItem } from '@atlaskit/menu';
import { token } from '@atlaskit/tokens';
import { getGlobalSettingsAdminItems } from '@atlassian/jira-atlassian-navigation-global-settings-home/src/index.tsx';
import { useOgTenantInfo } from '@atlassian/jira-eoc-tenant-info/src/controllers/index.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { useApplicationPermissions } from '@atlassian/jira-tenant-context-controller/src/components/application-permissions/index.tsx';
import { useIsAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-admin/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { EXPERIENCE_NAVIGATION_TOP_MENU, PACKAGE_NAME } from '../../../common/constants.tsx';
import { MenuLayout } from '../../../common/ui/menu/layout/index.tsx';
import { useManageUsersPermission } from '../../../controllers/manage-users-permission/index.tsx';
import { Home } from './home/main.tsx';
import messages from './messages.tsx';
import { Personal } from './personal/main.tsx';
import { Warning } from './warning/main.tsx';

export type MenuProps = {
	testIdPrefix?: string;
	titleId: string;
};

export const Menu = ({ testIdPrefix, titleId }: MenuProps) => {
	const { formatMessage } = useIntl();
	const isAdmin = useIsAdmin();
	const isSiteAdmin = useIsSiteAdmin();
	const appPermissions = useApplicationPermissions();
	const manageUsersPermission = useManageUsersPermission();
	const { isOpsAdmin, loading: isOgTenantInfoLoading } = useOgTenantInfo();

	const willShowNav4 = getWillShowNav4();

	const settingsNavigationItems = useMemo(
		() =>
			getGlobalSettingsAdminItems(
				formatMessage,
				appPermissions,
				{
					isAdmin,
					isSiteAdmin,
					manageUsersPermission,
					isOpsAdmin,
				},
				willShowNav4,
			),
		[
			formatMessage,
			appPermissions,
			isAdmin,
			isSiteAdmin,
			manageUsersPermission,
			willShowNav4,
			isOpsAdmin,
		],
	);

	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});

	useEffect(() => {
		if (!willShowNav4) onSuccess();
	}, [onSuccess, willShowNav4]);

	const testId = testIdConcat(testIdPrefix, 'menu-popup');
	const testIdHeader = testIdConcat(testId, 'header');

	if (willShowNav4) {
		return (
			<UFOSegment name="nav4-topNav-settings">
				<JSErrorBoundary id={MENU_ID.SETTINGS} packageName={PACKAGE_NAME} fallback="flag">
					<span data-testid={testId}>
						<MenuLayout isWide>
							<Section>
								<Personal testIdPrefix={testId} />

								{settingsNavigationItems.length > 0 && (
									<Home settingsDrawerItems={settingsNavigationItems} testIdPrefix={testId} />
								)}
								{manageUsersPermission.loading ? (
									<>
										<SkeletonHeadingItem
											testId={`${testId}--loading-skeleton--heading`}
											isShimmering
										/>
										<SkeletonItem
											testId={`${testId}--loading-skeleton--item`}
											isShimmering
											hasIcon
										/>
									</>
								) : (
									settingsNavigationItems.length === 0 && <Warning testIdPrefix={testId} />
								)}
							</Section>
						</MenuLayout>
					</span>
				</JSErrorBoundary>
			</UFOSegment>
		);
	}

	return (
		<span data-testid={testId}>
			<MenuLayout isWide>
				<Section>
					<SettingsTitle
						{...(testIdHeader != null ? { 'data-testid': testIdHeader } : {})}
						id={titleId}
					>
						{formatMessage(messages.settings)}
					</SettingsTitle>

					<Personal testIdPrefix={testId} />

					{settingsNavigationItems.length > 0 && (
						<Home settingsDrawerItems={settingsNavigationItems} testIdPrefix={testId} />
					)}
					{manageUsersPermission.loading ||
					(isOgTenantInfoLoading && fg('operations-tenant-info-endpoint-update')) ? (
						<>
							<SkeletonHeadingItem testId={`${testId}--loading-skeleton--heading`} isShimmering />
							<SkeletonItem testId={`${testId}--loading-skeleton--item`} isShimmering hasIcon />
						</>
					) : (
						settingsNavigationItems.length === 0 && <Warning testIdPrefix={testId} />
					)}
				</Section>
			</MenuLayout>
		</span>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SettingsTitle = styled.h2({
	font: token('font.heading.medium'),
	padding: `${token('space.300', '24px')} ${token('space.300', '24px')} ${token(
		'space.100',
		'8px',
	)} ${token('space.250', '20px')}
    `,
});
