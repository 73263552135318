import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import { createLocalExpirableStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import fetchJson from '@atlassian/jira-fetch/src/utils/as-json.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { CrossJoinTargetProduct } from '../../common/types.tsx';
import type { PermsApiResponse } from './types.tsx';

const URL = '/gateway/api/permissions/permitted';

export const hasProductAccess = async (
	product: CrossJoinTargetProduct,
	cloudId: string,
): Promise<boolean> => {
	const data = {
		permissionId: 'write',
		resourceId: `ari:cloud:${product}::site/${cloudId}`,
	};
	const response = await fetchJson<PermsApiResponse>(URL, {
		method: 'POST',
		body: JSON.stringify(data),
	});
	return response.permitted;
};

// Purposefully set this to 7 days as it's unlikely user can be kicked out of Confluence when they already have access
const PERMISSION_CACHE_KEY_EXPIRY_MS = 24 * 60 * 60 * 1000 * 7;
const crossJoinApiCache = createLocalExpirableStorageProvider(
	'cross-join-nudge-conf-perms-api-cache',
);

export const fetchCachedAccessCheck = async (
	createAnalyticsEvent: CreateUIAnalyticsEvent,
	product: CrossJoinTargetProduct,
	cloudId: string,
	touchpoint: string,
): Promise<boolean | null> => {
	const cacheKey = `cross.join.${product}.cache.key`;
	let permissionResponse = crossJoinApiCache.get(cacheKey);

	if (permissionResponse === undefined) {
		fireOperationalAnalytics(createAnalyticsEvent({}), 'accessCheck cacheMiss', {
			touchpoint,
			product,
		});
		try {
			permissionResponse = await hasProductAccess(product, cloudId);
		} catch (err) {
			// No PII from identity exception so fire operational analytics consistently
			fireOperationalAnalytics(createAnalyticsEvent({}), 'accessCheck fetchError', {
				touchpoint,
				product,
				err,
				sendToPrivacyUnsafeSplunk: true,
				skipSentry: true,
			});
			return null;
		}
		try {
			crossJoinApiCache.set(
				cacheKey,
				permissionResponse,
				Date.now() + PERMISSION_CACHE_KEY_EXPIRY_MS,
			);
		} catch (err) {
			fireOperationalAnalytics(createAnalyticsEvent({}), 'accessCheck setError', {
				touchpoint,
				product,
				err,
				skipSentry: true,
			});
			return permissionResponse;
		}
		return permissionResponse;
	}
	fireOperationalAnalytics(createAnalyticsEvent({}), 'accessCheck cacheHit', {
		touchpoint,
		product,
	});
	return permissionResponse;
};
