import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	upgrade: {
		defaultMessage: 'Upgrade',
		id: 'atlassian-navigation.profile.menu.upgrade-edition.upgrade',
		description: 'Provide user in Free Edition ability to upgrade to trial edition',
	},
	tryStandardPlan: {
		defaultMessage: 'Try the Standard plan',
		id: 'atlassian-navigation.profile.menu.upgrade-edition.try-standard-plan',
		description: 'Inform user in Free edition to try Standard Edition',
	},
	trialPeriod: {
		defaultMessage: 'free {trialLength}-day trial',
		id: 'atlassian-navigation.profile.menu.upgrade-edition.trial-period',
		description: 'Inform user about period of trial for Standard Edition',
	},
});
