import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';

export const CROSS_JOIN_NUDGE_JIRA_JPD_EXPERIMENT_KEY = 'cross_join_nudges_jira_to_jpd_experiment';
export const CROSS_JOIN_NUDGE_JIRA_JPD_GATE_KEY = 'cross_join_nudges_jira_to_jpd_gate';

export const NOT_ENROLLED = 'not-enrolled';
export const VARIATION = 'variation';
export const CONTROL = 'control';

export const getExperimentCohort = ({ fireExposure } = { fireExposure: false }) => {
	try {
		// eslint-disable-next-line jira/ff/unsafe-no-exposure
		const [expConfig, fireExperimentExposure] = UNSAFE_noExposureExp(
			'cross_join_nudges_jira_to_jpd_experiment',
		);
		fireExposure && fireExperimentExposure();
		return expConfig.get('cohort', NOT_ENROLLED);
	} catch {
		return NOT_ENROLLED;
	}
};

export const isExperimentEnabled = () => getExperimentCohort({ fireExposure: false }) === VARIATION;
