import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	title: {
		id: 'cross-join-nudges.request-access-error-flag.title',
		defaultMessage: "We couldn't add you to {productDisplayName}",
		description: 'Title of invite error flag',
	},
	description: {
		id: 'cross-join-nudges.request-access-error-flag.description',
		defaultMessage: "Try again in a few minutes. If you still aren't able to join, ",
		description: 'Description informing the user that request to join was not successfully sent',
	},
	action: {
		id: 'cross-join-nudges.request-access-error-flag.action',
		defaultMessage: 'contact support',
		description: 'Action to contact suport',
	},
});
