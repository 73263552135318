import React, { useMemo } from 'react';
import { CommerceExperienceDrawer } from '@atlassian/jira-commerce-experience-drawer/src/ui/index.tsx';
import {
	PRODUCT_DISCOVERY_PROJECT,
	projectTypeToCanonicalId,
	SERVICE_DESK_PROJECT,
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	toProjectType,
	type ProjectType,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useJsmPremiumPillExperiment } from '@atlassian/jira-edition-awareness-dropdown/src/common/utils.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fg } from '@atlassian/jira-feature-gating';
import { useProject } from '@atlassian/jira-navigation-apps-sidebar-common/src/controllers/project/index.tsx';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';
import {
	FREE_EDITION,
	getEditionForProject,
	PREMIUM_EDITION,
	STANDARD_EDITION,
	type ApplicationEdition,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { EDITION_AWARENESS, PACKAGE_NAME } from '../common/constants.tsx';
import { useSiteHasOnlyJira } from '../common/utils.tsx';
import { useIsImplicitJWMLicense } from '../controllers/is-implicit-jwm-license/index.tsx';
import { EditionAwarenessButton } from './edition-awareness-next/index.tsx';
import { FreePill } from './free-pill/index.tsx';
import { TrialPill } from './trial-pill/index.tsx';
import type { TrialPillProps } from './trial-pill/types.tsx';

// Experiment specific code to be removed during cleanup
const isEnrolledInJPDFreeToStandard = () => {
	const [config] = UNSAFE_noExposureExp('jpd-free-to-upgrade-pill-au-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};

/**
 * Commerce Experience Payment Method Embed experiment
 * @returns {boolean}
 * --------------------------------------------------------------
 */
const isEnrolledInCommerceExperiencePaymentMethodEmbed = () => {
	const [config] = UNSAFE_noExposureExp('commerce_experience_payment_method_embed');
	const cohort = config.get('cohort', 'not-enrolled');
	return cohort !== 'not-enrolled';
};
/**
 * --------------------------------------------------------------
 */

const ImplicitJWMTrialPill = (props: Omit<TrialPillProps, 'productKey' | 'projectType'>) => {
	const isImplicitJWMLicense = useIsImplicitJWMLicense();

	if (!isImplicitJWMLicense) {
		return null;
	}
	// productKey is set to "jira-software", because it only has an entitlement with JSW, not with JWM.
	// projectType is the real value ("business").
	return (
		<UFOSegment name="edition-awareness-implicit-jwm-premium-trial">
			<TrialPill
				{...props}
				productKey={projectTypeToCanonicalId(SOFTWARE_PROJECT)}
				projectType={CORE_PROJECT}
			/>
		</UFOSegment>
	);
};

const EditionAwarenessWithCommonAnalytics = () => {
	const isSiteAdmin = useIsSiteAdmin();

	const appEditions = useAppEditions();
	const { loading, data: project } = useProject();
	const siteHasOnlyJira = useSiteHasOnlyJira();

	const { getIsEligibleForJsmPremiumPillExperiment } = useJsmPremiumPillExperiment();
	// Show premium pill button for JSM premium trial experiment

	// If the JSW EA top nav relocation is active, we want to default the project type to JSW
	// so that the pill can show even on product-agnostic pages (e.g. "Your work")
	const defaultProjectType = useMemo(() => {
		if (siteHasOnlyJira) {
			return SOFTWARE_PROJECT;
		}
		if (getIsEligibleForJsmPremiumPillExperiment()) {
			// If the user is on the JSM assets page, we want to default the project type to JSM
			return SERVICE_DESK_PROJECT;
		}
		return undefined;
	}, [getIsEligibleForJsmPremiumPillExperiment, siteHasOnlyJira]);

	const projectType = toProjectType(project?.type) || defaultProjectType;

	if (loading || !projectType) {
		return null;
	}

	const edition = getEditionForProject(projectType, appEditions);

	return (
		<ContextualAnalyticsData
			attributes={{
				projectType,
				applicationEdition: edition,
				siteHasJswOnly: siteHasOnlyJira,
				isSiteAdmin,
			}}
		>
			<ProductAndEditionChecker
				projectType={projectType}
				edition={edition}
				siteHasOnlyJira={siteHasOnlyJira}
			/>
		</ContextualAnalyticsData>
	);
};

type ProductAndEditionCheckerProps = {
	projectType: ProjectType;
	edition: ApplicationEdition;
	siteHasOnlyJira: boolean;
};

const ProductAndEditionChecker = (props: ProductAndEditionCheckerProps) => {
	const { projectType, edition, siteHasOnlyJira } = props;

	if (projectType === SERVICE_DESK_PROJECT) {
		switch (edition) {
			case FREE_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsm-free-to-standard">
						<EditionAwarenessButton
							applicationEdition={edition}
							applicationKey="jira-servicedesk"
							projectType={projectType}
						/>
					</UFOSegment>
				);
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsw-jsm-standard-premium-trial">
						<TrialPill productKey="jira-servicedesk" projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === SOFTWARE_PROJECT) {
		switch (edition) {
			case FREE_EDITION:
				if (fg('jsw_free_edition_awareness_next')) {
					return (
						<UFOSegment name="edition-awareness-jsw-free">
							<EditionAwarenessButton
								applicationEdition={edition}
								applicationKey="jira-software"
								projectType={projectType}
								siteHasJswOnly={siteHasOnlyJira}
							/>
						</UFOSegment>
					);
				}

				return (
					<UFOSegment name="edition-awareness-jsw-jsm-free">
						<FreePill projectType={projectType} siteHasJswOnly={siteHasOnlyJira} />
					</UFOSegment>
				);
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jsw-jsm-standard-premium-trial">
						<TrialPill productKey="jira-software" projectType={projectType} edition={edition} />
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === PRODUCT_DISCOVERY_PROJECT) {
		if (fg('jpd_disable_edition_awareness')) {
			return null;
		}
		switch (edition) {
			case FREE_EDITION:
				if (isEnrolledInJPDFreeToStandard()) {
					return (
						<UFOSegment name="edition-awareness-jpd-free-to-standard">
							<EditionAwarenessButton
								applicationEdition="FREE_EDITION"
								applicationKey="jira-product-discovery"
								projectType={projectType}
							/>
						</UFOSegment>
					);
				}

				return null;
			case STANDARD_EDITION:
			case PREMIUM_EDITION:
				return (
					<UFOSegment name="edition-awareness-jpd-standard-premium-trial">
						<TrialPill
							productKey="jira-product-discovery"
							projectType={projectType}
							edition={edition}
						/>
					</UFOSegment>
				);
			default:
				return null;
		}
	} else if (projectType === CORE_PROJECT) {
		// We are supporting only JWM implicit licensing when the parent license is JSW
		switch (edition) {
			case FREE_EDITION:
				if (siteHasOnlyJira) {
					if (fg('jsw_free_edition_awareness_next')) {
						return (
							<UFOSegment name="edition-awareness-jsw-free">
								<EditionAwarenessButton
									applicationEdition={edition}
									applicationKey="jira-software"
									projectType={projectType}
									siteHasJswOnly={siteHasOnlyJira}
								/>
							</UFOSegment>
						);
					}

					return (
						<UFOSegment name="edition-awareness-jsw-jsm-free">
							<FreePill projectType={CORE_PROJECT} siteHasJswOnly={siteHasOnlyJira} />
						</UFOSegment>
					);
				}
				return null;
			case STANDARD_EDITION:
				if (siteHasOnlyJira) {
					// productKey is set to "jira-software", because it only has an entitlement with JSW, not with JWM.
					// projectType is the real value ("business").
					return (
						<UFOSegment name="edition-awareness-implicit-jwm-standard-trial">
							<TrialPill edition={edition} productKey="jira-software" projectType={CORE_PROJECT} />
						</UFOSegment>
					);
				}
				return null;
			case PREMIUM_EDITION:
				// Core projects are partially supported - only for implicit JWM, on premium, on the top nav
				return <ImplicitJWMTrialPill edition={edition} />;
			default:
				return null;
		}
	}
	return null;
};

export const EditionAwareness = () => {
	// This component is not rendered on the server, we don't have enough information to determine if it will be renderer or not
	// Don't remove because it may cause the SSR to fail, at this point it because the EditonAwarenessButton is using useLazyLoadQuery
	if (__SERVER__) {
		return null;
	}

	return (
		<JSErrorBoundary
			id={EDITION_AWARENESS}
			packageName={PACKAGE_NAME}
			fallback="unmount"
			teamName="growth-tako"
		>
			{isEnrolledInCommerceExperiencePaymentMethodEmbed() ? <CommerceExperienceDrawer /> : null}
			<UFOSegment name="edition-awareness">
				<EditionAwarenessWithCommonAnalytics />
			</UFOSegment>
		</JSErrorBoundary>
	);
};
