import React from 'react';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazy } from '@atlassian/react-loosely-lazy';
import { PACKAGE_NAME } from '../../common/constants.tsx';
import type { Menu } from './menu/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyMenu = lazy<typeof Menu>(() =>
	import(/* webpackChunkName: "async-your-work-dropdown-menu" */ './menu').then(({ Menu }) => Menu),
);

export const AsyncMenu = () => (
	<JSErrorBoundary
		id="your-work-dropdown-menu"
		packageName={PACKAGE_NAME}
		teamName="navigation"
		fallback="flag"
	>
		<Placeholder name="your-work-dropdown-menu" fallback={null}>
			<LazyMenu />
		</Placeholder>
	</JSErrorBoundary>
);
