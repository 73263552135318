import { useEffect } from 'react';
import { CORE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import { expVal, expValEquals, UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import type { ApplicationKey } from '@atlassian/jira-shared-types/src/application-key.tsx';
import type { ApplicationEdition } from '@atlassian/jira-shared-types/src/edition.tsx';
import type { SupportedProjectType } from '../common/types.tsx';

/**
 * Experiment specific code to be removed during cleanup
 */
const trackJDPFreeToStandardExposure = ({
	createAnalyticsEvent,
	applicationKey,
	applicationEdition,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	createAnalyticsEvent: ReturnType<typeof useAnalyticsEvents>['createAnalyticsEvent'];
}) => {
	fireTrackAnalytics(
		createAnalyticsEvent({
			type: 'sendTrackEvent',
			action: 'exposed',
			actionSubject: 'feature',
		}),
		'feature exposed',
		'editionAwarenessButton',
		{
			flagKey: 'jpd-free-to-upgrade-pill-au-awareness',
			value: expVal('jpd-free-to-upgrade-pill-au-awareness', 'cohort', 'not-enrolled'),
			growthInitiative: 'transmuters',
			applicationKey,
			applicationEdition,
		},
	);
};

/**
 * Experiment specific code to be removed during cleanup
 */
const useShouldSeeJPDFreeToStandardExperiment = ({
	applicationKey,
	applicationEdition,
	canUpgrade,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	canUpgrade: boolean;
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const [config] = UNSAFE_noExposureExp('jpd-free-to-upgrade-pill-au-awareness');
	const cohort = config.get('cohort', 'not-enrolled');
	const isEnrolled = cohort !== 'not-enrolled';

	const shouldSeeButton =
		canUpgrade &&
		applicationKey === 'jira-product-discovery' &&
		applicationEdition === 'FREE_EDITION';

	useEffect(() => {
		if (!isEnrolled) {
			return;
		}

		if (!shouldSeeButton) {
			return;
		}

		trackJDPFreeToStandardExposure({ createAnalyticsEvent, applicationEdition, applicationKey });
	}, [isEnrolled, createAnalyticsEvent, applicationEdition, applicationKey, shouldSeeButton]);

	return cohort === 'variation' && shouldSeeButton;
};

export const useShouldSeeButton = ({
	applicationKey,
	applicationEdition,
	canUpgrade,
	projectType,
}: {
	applicationKey: ApplicationKey;
	applicationEdition: ApplicationEdition;
	canUpgrade: boolean;
	projectType: SupportedProjectType;
}) => {
	const shouldSeeJPDFreeToStandardExperiment = useShouldSeeJPDFreeToStandardExperiment({
		applicationEdition,
		applicationKey,
		canUpgrade,
	});

	if (shouldSeeJPDFreeToStandardExperiment) {
		return true;
	}

	if (canUpgrade && applicationEdition === 'FREE_EDITION') {
		// Released 100% for JSM
		if (applicationKey === 'jira-servicedesk') {
			return true;
		}
		if (applicationKey === 'jira-software') {
			// Checking the JWM experiment
			if (projectType === CORE_PROJECT) {
				return expValEquals('edition_awareness_on_jwm', 'isEnabled', true);
			}
			// Released 100% for JSW
			return true;
		}
	}
	return false;
};
