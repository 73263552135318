/**
 * @generated SignedSource<<f6734b28ab7bbf05b80872ca85f1f0c6>>
 * @relayHash 628e9875849956faca4368d5ee0794a9
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

// @relayRequestID ee8c7a69cd7d2798a4a3342a54918114b9efdef1a99d85d3cff3b45a17ce01cc

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type AtlaskitAtlassianNavigationNav4Query$variables = Record<PropertyKey, never>;
export type AtlaskitAtlassianNavigationNav4Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Profile">;
};
export type AtlaskitAtlassianNavigationNav4Query = {
  response: AtlaskitAtlassianNavigationNav4Query$data;
  variables: AtlaskitAtlassianNavigationNav4Query$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "name": "AtlaskitAtlassianNavigationNav4Query",
    "selections": [
      {
        "kind": "FragmentSpread",
        "name": "Nav4Profile"
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "AtlaskitAtlassianNavigationNav4Query",
    "selections": [
      {
        "concreteType": "AuthenticationContext",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "kind": "ScalarField",
                "name": "__typename"
              },
              {
                "kind": "ScalarField",
                "name": "picture"
              },
              {
                "kind": "ScalarField",
                "name": "id"
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "id": "ee8c7a69cd7d2798a4a3342a54918114b9efdef1a99d85d3cff3b45a17ce01cc",
    "metadata": {},
    "name": "AtlaskitAtlassianNavigationNav4Query",
    "operationKind": "query",
    "text": null
  }
};

(node as any).hash = "117383646be1f212dbc165252eccb286";

export default node;
