import getStartStandardTrialUrlQueryParameters from '@atlassian/jira-relay/src/__generated__/uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery$parameters';
import type { uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery$variables } from '@atlassian/jira-relay/src/__generated__/uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery.graphql';
import { JSResourceForInteraction } from '@atlassian/react-async';
import { createEntryPoint } from '@atlassian/react-entrypoint';

type EntryPointParams = uiGrowthJsmUpsellModal_GetStartStandardTrialUrlQuery$variables;

export const jsmUpsellModalEntryPoint = createEntryPoint({
	root: JSResourceForInteraction(
		() => import(/* webpackChunkName: "async-jsm-upsell-modal" */ './src'),
	),
	getPreloadProps: ({ cloudIds }: EntryPointParams) => ({
		queries: {
			getStartStandardTrialUrlQuery: {
				parameters: getStartStandardTrialUrlQueryParameters,
				variables: {
					cloudIds,
				},
			},
		},
	}),
});
