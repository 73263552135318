import type { LicensedProducts } from '@atlassian/jira-shared-types/src/tenant-context.tsx';
import { hasProductAccess } from '../permission-check/index.tsx';
import { fetchIsRequestAccessCapable } from '../request-capabilities/index.tsx';
import { isSite7DaysOld } from '../site-eligibility/index.tsx';

export type JpdCJNIneligibilityReason =
	| 'NEW_SITE_LESS_THAN_7_DAYS_OLD'
	| 'ALREADY_HAVE_ACCESS'
	| 'NOT_ELIGIBLE_FOR_REQUEST_ACCESS'
	| 'NO_JPD_LICENCE';

export type JpdCJNEligibilityCheckInput = {
	cloudId: string;
	licensedProducts: LicensedProducts;
};

export type JpdCJNEligibilityCheckResult =
	| {
			eligible: false;
			ineligibilityReason: JpdCJNIneligibilityReason;
	  }
	| { eligible: true };

export const checkJpdCrossJoinNudgeEligibility = async ({
	cloudId,
	licensedProducts,
}: JpdCJNEligibilityCheckInput): Promise<JpdCJNEligibilityCheckResult> => {
	if (!licensedProducts['jira-product-discovery']) {
		return {
			eligible: false,
			ineligibilityReason: 'NO_JPD_LICENCE',
		};
	}

	if (!(await isSite7DaysOld('jira-product-discovery', cloudId))) {
		return {
			eligible: false,
			ineligibilityReason: 'NEW_SITE_LESS_THAN_7_DAYS_OLD',
		};
	}

	if (await hasProductAccess('jira-product-discovery', cloudId)) {
		return {
			eligible: false,
			ineligibilityReason: 'ALREADY_HAVE_ACCESS',
		};
	}

	if (!(await fetchIsRequestAccessCapable('jira-product-discovery', cloudId))) {
		return {
			eligible: false,
			ineligibilityReason: 'NOT_ELIGIBLE_FOR_REQUEST_ACCESS',
		};
	}

	return {
		eligible: true,
	};
};
