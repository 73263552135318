/**
 * @generated SignedSource<<11ae56ad571b3e82e9575f67f7c57934>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type Nav4Profile$data = {
  readonly me: {
    readonly user: {
      readonly picture: AGG$URL;
    } | null | undefined;
  };
  readonly " $fragmentType": "Nav4Profile";
};
export type Nav4Profile$key = {
  readonly " $data"?: Nav4Profile$data;
  readonly " $fragmentSpreads": FragmentRefs<"Nav4Profile">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "name": "Nav4Profile",
  "selections": [
    {
      "concreteType": "AuthenticationContext",
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "kind": "ScalarField",
              "name": "picture"
            }
          ]
        }
      ]
    }
  ],
  "type": "Query"
};

(node as any).hash = "60c71451f3b586c706ead3f3785a05c7";

export default node;
