export const JIRA_SETTINGS_SECTION_IDS = {
	SETTINGS_TITLE: 'settings-title',
	SITE_ADMIN: 'site-admin',
	SYSTEM_ADMIN: 'system-admin',
	OPS_ADMIN: 'ops-admin',
	TRUSTED_USER: 'trusted-user',
	PERSONAL: 'personal-settings',
} as const;

export const ADMIN_SETTINGS_ITEM_IDS = {
	SYSTEM: 'admin-system-menu',
	APPLICATIONS: 'admin-applications-menu',
	ISSUES: 'admin-issues-menu',
	PLUGINS: 'admin-plugins-menu',
	RECOMMENDATIONS: 'admin-recommendations',
	MANAGE_PROJECTS: 'admin-manage-project-menu',
} as const;

export const SITE_ADMIN_SETTINGS_ITEM_IDS = {
	USER_MANAGEMENT: 'user-management-section',
	BILLING: 'admin-billing-section',
} as const;

export const OPS_ADMIN_SETTINGS_ITEM_IDS = {
	APPLICATIONS: 'admin-applications-menu',
} as const;

export const TRUSTED_USER_SETTINGS_ITEM_IDS = {
	USER_MANAGEMENT: 'trusted-admin-user-management-section',
	DISCOVER_APPLICATIONS: 'discover-applications-section',
} as const;

export const SITE_ADMIN_SETTINGS_ITEM_HREFS = {
	USER_MANAGEMENT: '/admin/users?referrerName=JIRA',
	BILLING: '/admin/billing-preview?referrerName=JIRA',
} as const;
