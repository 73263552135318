import React, { useEffect, useCallback, useMemo } from 'react';
import type { SOFTWARE_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import type {
	VARIATION,
	CONTROL,
	NOT_ENROLLED,
} from '@atlassian/jira-common-util-get-experiment-group/src/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { ModalEntryPointContainer } from '@atlassian/jira-entry-point-modal-container/src/index.tsx';
import { useEntryPoint } from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import type { UserCountData } from '@atlassian/jira-growth-utils/src/common/types.tsx';
import { useGetJswUserCount } from '@atlassian/jira-growth-utils/src/services/get-jsw-user-count/index.tsx';
import { jswUpsellModalEntryPoint } from '@atlassian/jira-jsw-upsell-modal/entrypoint.tsx';
import { useIsProductUsingCcp } from '@atlassian/jira-billing/src/controllers/billing-system/use-is-product-using-ccp.tsx';
import { fireUIAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';
import { LIMITED_USERS_LEFT_COUNT, NO_USERS_LEFT_COUNT } from '../../../common/constants.tsx';
import { LimitedUsersLeftButton, NoUsersLeftButton, UpgradeButton } from './buttons/index.tsx';

export type UsersRemainingCtaButtonProps = {
	projectType: typeof SOFTWARE_PROJECT;
	siteHasJswOnly: boolean;
};

type JSWExperimentCohort = typeof NOT_ENROLLED | typeof VARIATION | typeof CONTROL;

export function UsersRemainingCtaButton(props: UsersRemainingCtaButtonProps) {
	const { projectType, siteHasJswOnly } = props;
	const isUsingCcp = useIsProductUsingCcp(projectType);
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const cloudId = useCloudId();
	const isSiteAdmin = useIsSiteAdmin();

	const shouldUseCache = useCallback(
		(userCountData: UserCountData) => userCountData.unitCount < NO_USERS_LEFT_COUNT,
		[],
	);
	const { data } = useGetJswUserCount({
		cloudId,
		shouldUseCache,
		skip: !isSiteAdmin,
	});

	const userCount = data?.unitCount;

	// Fire viewed analytics once on initial render
	useEffect(() => {
		if (userCount !== undefined) {
			fireUIAnalytics(
				createAnalyticsEvent({
					action: 'viewed',
					actionSubject: 'button',
				}),
				'usersRemainingFreePill',
				{ userCount },
			);
		}
	}, [createAnalyticsEvent, userCount]);

	const fireButtonAnalytics = useCallback(
		(action: string) =>
			fireUIAnalytics(
				createAnalyticsEvent({
					action,
					actionSubject: 'button',
				}),
				'editionAwarenessFreePill',
				{
					userCount,
				},
			),
		[createAnalyticsEvent, userCount],
	);

	const onButtonClick = () => fireButtonAnalytics('clicked');
	const onHoverButton = () => fireButtonAnalytics('hovered');

	const [mustBnConfig] = UNSAFE_noExposureExp('jsw_upgrade_skip_trial_buy_now');
	const jswBuyNowExperimentCohort = useMemo(
		() => mustBnConfig.get<JSWExperimentCohort>('cohort', 'not-enrolled'),
		[mustBnConfig],
	);

	const isCcpJswOnlySite = isUsingCcp && siteHasJswOnly;
	const shouldRequestSkipTrialFields =
		isCcpJswOnlySite && jswBuyNowExperimentCohort !== 'not-enrolled';

	const { entryPointActions, entryPointReferenceSubject } = useEntryPoint(
		jswUpsellModalEntryPoint,
		{ cloudIds: [cloudId], shouldRequestSkipTrialFields },
	);

	const triggerRef = useEntryPointButtonTrigger(entryPointActions);
	const Button = getUsersRemainingButton(userCount);

	return (
		<>
			<Button triggerRef={triggerRef} onButtonClick={onButtonClick} onHoverButton={onHoverButton} />
			<ModalEntryPointContainer
				entryPointReferenceSubject={entryPointReferenceSubject}
				id="jsw-upsell-modal"
				packageName="edition-awareness"
				teamName="growth-tako"
				runtimeProps={{
					shouldRequestSkipTrialFields,
				}}
				entryPointActions={entryPointActions}
			/>
		</>
	);
}

const getUsersRemainingButton = (userCount?: number) => {
	if (userCount === undefined) {
		return UpgradeButton;
	}

	// 10 user limit, show "No Users Left" button
	if (userCount >= NO_USERS_LEFT_COUNT) {
		return NoUsersLeftButton;
	}

	// 7-9 users show "Limited Users Left" button
	if (userCount >= LIMITED_USERS_LEFT_COUNT && userCount < NO_USERS_LEFT_COUNT) {
		return LimitedUsersLeftButton;
	}

	return UpgradeButton;
};
