import { useMemo } from 'react';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import type { CrossJoinIntegraton } from '../../common/types.tsx';
import { getUserProperty, setUserProperty } from '../common/user-properties/index.tsx';

const VISITED_COUNT_KEY = 'CROSS_JOIN_NUDGE_CONFLUENCE_VISITED_COUNT';
const ONE_DAY_IN_MILLISECONDS = 86400000;
const crossJoinNudgeVisitedCache = createLocalStorageProvider('cross-join-nudge-visited');

const visitedKeys: Record<CrossJoinIntegraton, string> = {
	crossJoinNudgesConfluence: 'cross.join.nudges.confluence.visited',
	crossJoinNudgesJiraToJpd: 'cross.join.nudges.jpd.visited',
	crossJoinNudgesYourWork: 'cross.join.nudges.your.work',
};

export const isFeatureVisited = (integration: CrossJoinIntegraton, snoozeForDays: number) => {
	try {
		if (getFeatureVisited(integration)) {
			return true;
		}
		setFeatureVisited(integration, snoozeForDays);

		// A small cohort of users have local storage completely disabled.
		// This code detects that and always returns visited=true for those users
		if (!getFeatureVisited(integration)) {
			fireErrorAnalytics({
				meta: {
					id: 'featureVisitedLocalStorageNotAvailabe',
					packageName: 'jiraCrossJoinNudges',
				},
			});
			return true;
		}
		return false;
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (error: any) {
		fireErrorAnalytics({
			meta: {
				id: 'useFeatureVisited',
				packageName: 'jiraCrossJoinNudges',
			},
			error,
			skipSentry: true,
			sendToPrivacyUnsafeSplunk: true,
		});
		return true;
	}
};

const setFeatureVisited = (integration: CrossJoinIntegraton, numOfDaysSnoozed: number) => {
	const key = visitedKeys[integration];
	const SNOOZE_UNTIL_TIMESTAMP = new Date().getTime() + ONE_DAY_IN_MILLISECONDS * numOfDaysSnoozed;
	crossJoinNudgeVisitedCache.set(key, SNOOZE_UNTIL_TIMESTAMP);
};

export const snoozeFeatureFor7Days = (integration: CrossJoinIntegraton) => {
	setFeatureVisited(integration, 7);
};

export const snoozeFeatureFor30Days = (integration: CrossJoinIntegraton) => {
	setFeatureVisited(integration, 30);
};

const getFeatureVisited = (integration: CrossJoinIntegraton) => {
	const key = visitedKeys[integration];
	const snoozeUntilTimestampString = crossJoinNudgeVisitedCache.get(key);

	if (snoozeUntilTimestampString !== null) {
		const SNOOZE_UNTIL_TIMESTAMP = parseInt(snoozeUntilTimestampString, 10);
		const TIMESTAMP = new Date().getTime();
		if (!Number.isNaN(SNOOZE_UNTIL_TIMESTAMP) && TIMESTAMP < SNOOZE_UNTIL_TIMESTAMP) {
			return true;
		}
	}
	return false;
};

export const getFeatureVisitedCount = async (
	accountId: string,
	propertyKey = VISITED_COUNT_KEY,
): Promise<number> => {
	if (!accountId) return 0;

	const visitedCount = await getUserProperty<number>({
		accountId,
		propertyKey,
		defaultValue: 0,
	});

	return !Number.isNaN(visitedCount.value) ? visitedCount.value : 0;
};

export const increaseFeatureVisitedCount = async (
	accountId: string,
	incrementedCount: number,
	propertyKey = VISITED_COUNT_KEY,
) => {
	await setUserProperty<number>({
		accountId,
		propertyKey,
		propertyValue: incrementedCount,
	});
};
export const useSnooze = (integration: CrossJoinIntegraton) => {
	const key = visitedKeys[integration];
	const accountId = useAccountId() || '';
	const countKey = `${key}.count`;
	return useMemo(
		() => ({
			snoozeFor7Days: () => snoozeFeatureFor7Days(integration),
			snoozeFor30Days: () => snoozeFeatureFor30Days(integration),
			getVisitedCount: () => getFeatureVisitedCount(accountId, countKey),
			increaseVisitedCount: (incrementedCount: number) =>
				increaseFeatureVisitedCount(accountId, incrementedCount, countKey),
			isVisited: () => getFeatureVisited(integration),
		}),
		[accountId, countKey, integration],
	);
};
