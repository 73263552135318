import React from 'react';
import { PERSONAL_SETTINGS_PAGE_MODULE } from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import { ForgeAnalyticsWrapper } from '../../common/ui/index.tsx';
import type {
	AnalyticsWrapperPropsView,
	AnalyticsWrapperPropsTrigger,
} from '../../common/ui/types.tsx';
import { MODULE_TYPE_TRIGGER } from '../../constants.tsx';

export const ViewAnalyticsWrapper = (props: AnalyticsWrapperPropsView) => (
	<ForgeAnalyticsWrapper
		module={PERSONAL_SETTINGS_PAGE_MODULE}
		shouldFireInitializedEvent
		{...props}
	/>
);

export const TriggerAnalyticsWrapper = (props: AnalyticsWrapperPropsTrigger) => (
	<ForgeAnalyticsWrapper
		module={PERSONAL_SETTINGS_PAGE_MODULE}
		{...props}
		moduleType={MODULE_TYPE_TRIGGER}
	/>
);
