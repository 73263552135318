import { getUserPropertiesUrl } from '@atlassian/jira-common-rest/src/api/latest/user-properties/index.tsx';
import { getDefaultOptions } from '@atlassian/jira-fetch/src/utils/fetch-default-options-with-strict-mode.tsx';
import type { AccountId, BaseUrl } from '@atlassian/jira-shared-types/src/general.tsx';

export const deleteUserProperties = async (
	baseUrl: BaseUrl,
	accountId: AccountId,
	propertyKey: string, // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
	const url = getUserPropertiesUrl(accountId, propertyKey);
	return fetch(url, {
		...getDefaultOptions(url),
		method: 'DELETE',
	});
};
