import { useState, useEffect, useMemo } from 'react';
import type { CrossJoinIntegraton } from '../../common/types.tsx';
import { useSnooze } from '../caching/index.tsx';

export const useVisitedCount = (isEnrolled: boolean, integration: CrossJoinIntegraton) => {
	const [visitedCount, setVisitedCount] = useState(0);
	const [isLoading, setIsLoading] = useState(true);
	const { getVisitedCount } = useSnooze(integration);

	useEffect(() => {
		const fetch = async () => {
			const count = await getVisitedCount();
			setVisitedCount(count);
			setIsLoading(false);
		};
		if (isEnrolled) {
			fetch();
		}
	}, [getVisitedCount, isEnrolled]);

	return useMemo(() => ({ visitedCount, isLoading }), [visitedCount, isLoading]);
};
