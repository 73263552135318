import { useCallback } from 'react';
import { type ContextResolver, useRecommendations } from '@atlassian/app-recommendations-kit';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useSubProduct } from '@atlassian/jira-subproduct/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { EntityList, type EntityId } from '../dynamic-messaging/index.tsx';

const CROSS_JOIN_NUDGE_RECOMMENDATIONS_USE_CASE = 'crossJoinNudge';

export interface useNudgeRecommendationsProps {
	sessionId: string;
}

export function useNudgeRecommendations({ sessionId }: useNudgeRecommendationsProps) {
	const cloudId = useCloudId();
	const subProduct = useSubProduct();

	const contextResolver: ContextResolver = useCallback(
		() => ({
			product: 'jira',
			useCase: CROSS_JOIN_NUDGE_RECOMMENDATIONS_USE_CASE,
			tenantId: cloudId,
			// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
			subproduct: subProduct as string,
			sessionId,
		}),
		[cloudId, subProduct, sessionId],
	);

	const { getRecommendations } = useRecommendations({ contextResolver });

	return useCallback(async () => {
		try {
			const result = await getRecommendations(1, asEntityIdList(EntityList));

			const { response: { data } = {} } = result;

			// This type assertion is necessary because useRecommendations don't support generic entityId
			const recommendedEntityId =
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				(data && data.length > 0 && (data[0].entityId as EntityId)) || undefined;

			return recommendedEntityId;
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			fireErrorAnalytics({
				meta: {
					id: 'recommendationFetchFailed',
					packageName: 'jiraCrossJoinNudges',
					teamName: 'groot-growth',
				},
				error,
			});
			throw new Error('Get nudge recommendations error');
		}
	}, [getRecommendations]);
}

const asEntityIdList = (entities: typeof EntityList) =>
	entities.map((entity) => ({ entityId: entity }));
