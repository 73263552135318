import {
	useRegisterNudgeTarget as useRegisterPlatformNudgeTarget,
	type NudgeTarget,
} from '@atlassiansox/nudge-tooltip';
import { functionWithCondition } from '@atlassian/jira-feature-flagging-utils';
import { fg } from '@atlassian/jira-feature-gating';
import { useRegisterNudgeTarget as useRegisterJiraNudgeTarget } from '../../common/ui/do-not-use-directly-portalled-nudge/index.tsx';

export const useRegisterNudgeTarget = functionWithCondition<
	<T extends HTMLElement>(id: string, shouldRegister?: boolean) => NudgeTarget<T>
>(
	() => fg('jsw_portalled_nudge_migration_and_choreographing'),
	useRegisterPlatformNudgeTarget,
	useRegisterJiraNudgeTarget,
);
