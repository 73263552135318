import React, { type FunctionComponent } from 'react';
import { styled } from '@compiled/react';
import { fg } from '@atlassian/jira-feature-gating';
import { FormattedMessage, useIntl } from '@atlassian/jira-intl';
import { messages } from '../messages.tsx';

export const SlackSplash: FunctionComponent = () => {
	const { formatMessage } = useIntl();

	return (
		<SlackSplashWrapper data-testid="third-party-nudge.ui.third-party-nudge-flag.splashes.slack-splash.slack-splash-wrapper">
			<svg
				width="412"
				height="128"
				viewBox="0 0 412 128"
				fill="none"
				xmlns="http://www.w3.org/2000/svg"
			>
				<path
					d="M0 4C0 1.79086 1.79086 0 4 0H408C410.209 0 412 1.79086 412 4V128H0V4Z"
					fill="url(#paint0_linear_914_2569)"
				/>
				<g filter="url(#filter0_dd_914_2569)">
					<path
						d="M99 22.47C99 21.1059 100.106 20 101.47 20H364.53C365.894 20 367 21.1059 367 22.47V105C367 107.209 365.209 109 363 109H103C100.791 109 99 107.209 99 105V22.47Z"
						fill="white"
						fillOpacity="0.98"
					/>
					<rect
						width="24"
						height="24"
						transform="translate(111 28)"
						fill="white"
						fillOpacity="0.01"
					/>
					<path
						d="M131.846 30.25H122.543C122.543 32.4958 124.425 34.3238 126.737 34.3238H128.458V35.9168C128.458 38.1626 130.34 39.9906 132.652 39.9906V31.0334C132.652 30.5895 132.303 30.25 131.846 30.25Z"
						fill="#2684FF"
					/>
					<path
						d="M127.248 34.7416H117.945C117.945 36.9875 119.827 38.8155 122.14 38.8155H123.86V40.4345C123.86 42.6804 125.742 44.5084 128.055 44.5084V35.5251C128.055 35.1072 127.705 34.7416 127.248 34.7416Z"
						fill="url(#paint1_linear_914_2569)"
					/>
					<path
						d="M122.65 39.2594H113.348C113.348 41.5052 115.23 43.3332 117.542 43.3332H119.263V44.9262C119.263 47.172 121.145 49 123.457 49V40.0428C123.457 39.5989 123.081 39.2594 122.65 39.2594Z"
						fill="url(#paint2_linear_914_2569)"
					/>
					<text
						fill="#091E42"
						xmlSpace="preserve"
						fontSize="8"
						fontWeight="bold"
						letterSpacing="0em"
					>
						<tspan x="138" y="35.8438">
							{formatMessage(messages.slackExampleIntegrationName)}
						</tspan>
					</text>
					<text
						fill="#091E42"
						xmlSpace="preserve"
						fontSize="9"
						fontWeight="600"
						letterSpacing="0em"
					>
						<tspan x="138" y="51.87">
							&#x1f514;
						</tspan>
					</text>
					<text
						fill="#091E42"
						xmlSpace="preserve"
						fontSize="9"
						fontWeight="600"
						letterSpacing="0em"
					>
						<tspan x="150" y="50.6992">
							<FormattedMessage
								{...(fg('jira-issue-terminology-refresh-m3')
									? messages.slackExampleTitleIssueTermRefresh
									: messages.slackExampleTitle)}
								values={{
									tag: (chunks) => (
										<tspan
											fill="#0055CC"
											xmlSpace="preserve"
											fontSize="9"
											fontWeight="600"
											letterSpacing="0em"
										>
											{chunks}
										</tspan>
									),
								}}
							/>
						</tspan>
					</text>
					<path
						d="M142 75C142 76.1046 141.105 77 140 77C138.895 77 138 76.1046 138 75L138 60C138 58.8954 138.895 58 140 58C141.105 58 142 58.8954 142 60L142 75Z"
						fill="#2F78FC"
					/>
					<rect x="150" y="58.4999" width="181" height="6" rx="3" fill="#DCDFE4" />
					<rect x="150" y="70.5002" width="72" height="6" rx="3" fill="#DCDFE4" />
					<rect x="226" y="70.5002" width="72" height="6" rx="3" fill="#DCDFE4" />
					<rect
						x="137.3"
						y="85.3"
						width="61.9924"
						height="15.4"
						rx="1.48113"
						stroke="#B3B9C4"
						strokeWidth="0.6"
					/>
					<text
						fill="#626F86"
						xmlSpace="preserve"
						fontSize="9"
						fontWeight="500"
						letterSpacing="0px"
					>
						<tspan x="147.26" y="96.1992">
							{formatMessage(messages.integrationExampleCommentButton)}
						</tspan>
					</text>
					<rect
						x="205.893"
						y="85.3"
						width="60.9924"
						height="15.4"
						rx="1.48113"
						stroke="#B3B9C4"
						strokeWidth="0.6"
					/>
					<text
						fill="#626F86"
						xmlSpace="preserve"
						fontSize="9"
						fontWeight="500"
						letterSpacing="0px"
					>
						<tspan x="215.414" y="96.1992">
							{formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.integrationExampleEditIssueButtonIssueTermRefresh
									: messages.integrationExampleEditIssueButton,
							)}
						</tspan>
					</text>
					<rect
						x="273.485"
						y="85.3"
						width="70.9924"
						height="15.4"
						rx="1.48113"
						stroke="#B3B9C4"
						strokeWidth="0.6"
					/>
					<text
						fill="#626F86"
						xmlSpace="preserve"
						fontSize="9"
						fontWeight="500"
						letterSpacing="0px"
					>
						<tspan x="282.992" y="96.1992">
							{formatMessage(messages.integrationExampleOpenInJiraButton)}
						</tspan>
					</text>
					<rect x="45" y="20" width="42" height="42" rx="4" fill="white" />
					<path
						d="M58.4623 44.4302C58.4623 45.9334 57.2343 47.1614 55.731 47.1614C54.2278 47.1614 52.9998 45.9334 52.9998 44.4302C52.9998 42.9269 54.2278 41.6989 55.731 41.6989H58.4623V44.4302Z"
						fill="#E01E5A"
					/>
					<path
						d="M59.8376 44.4302C59.8376 42.927 61.0657 41.699 62.5689 41.699C64.0722 41.699 65.3002 42.927 65.3002 44.4302V51.269C65.3002 52.7722 64.0722 54.0002 62.5689 54.0002C61.0657 54.0002 59.8376 52.7722 59.8376 51.269V44.4302Z"
						fill="#E01E5A"
					/>
					<path
						d="M62.5689 33.4624C61.0657 33.4624 59.8376 32.2344 59.8376 30.7311C59.8376 29.2279 61.0657 27.9999 62.5689 27.9999C64.0722 27.9999 65.3002 29.2279 65.3002 30.7311V33.4624H62.5689Z"
						fill="#36C5F0"
					/>
					<path
						d="M62.5697 34.8384C64.073 34.8384 65.301 36.0664 65.301 37.5697C65.301 39.0729 64.073 40.301 62.5697 40.301H55.731C54.2278 40.301 52.9998 39.0729 52.9998 37.5697C52.9998 36.0664 54.2278 34.8384 55.731 34.8384H62.5697Z"
						fill="#36C5F0"
					/>
					<path
						d="M73.5364 37.5697C73.5364 36.0664 74.7644 34.8384 76.2676 34.8384C77.7709 34.8384 78.9989 36.0664 78.9989 37.5697C78.9989 39.0729 77.7709 40.301 76.2676 40.301H73.5364V37.5697Z"
						fill="#2EB67D"
					/>
					<path
						d="M72.161 37.5699C72.161 39.0731 70.933 40.3011 69.4297 40.3011C67.9265 40.3011 66.6985 39.0731 66.6985 37.5699V30.7311C66.6985 29.2279 67.9265 27.9999 69.4297 27.9999C70.933 27.9999 72.161 29.2279 72.161 30.7311V37.5699Z"
						fill="#2EB67D"
					/>
					<path
						d="M69.4295 48.5378C70.9328 48.5378 72.1608 49.7658 72.1608 51.2691C72.1608 52.7724 70.9328 54.0004 69.4295 54.0004C67.9262 54.0004 66.6982 52.7724 66.6982 51.2691V48.5378H69.4295Z"
						fill="#ECB22E"
					/>
					<path
						d="M69.4297 47.1614C67.9265 47.1614 66.6985 45.9334 66.6985 44.4302C66.6985 42.9269 67.9265 41.6989 69.4297 41.6989H76.2685C77.7717 41.6989 78.9997 42.9269 78.9997 44.4302C78.9997 45.9334 77.7717 47.1614 76.2685 47.1614H69.4297Z"
						fill="#ECB22E"
					/>
				</g>
				<defs>
					<filter
						id="filter0_dd_914_2569"
						x="33"
						y="8"
						width="346"
						height="113"
						filterUnits="userSpaceOnUse"
						colorInterpolationFilters="sRGB"
					>
						<feFlood floodOpacity="0" result="BackgroundImageFix" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset />
						<feGaussianBlur stdDeviation="6" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.56 0"
						/>
						<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_914_2569" />
						<feColorMatrix
							in="SourceAlpha"
							type="matrix"
							values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
							result="hardAlpha"
						/>
						<feOffset />
						<feGaussianBlur stdDeviation="0.5" />
						<feColorMatrix
							type="matrix"
							values="0 0 0 0 0.0117647 0 0 0 0 0.0156863 0 0 0 0 0.0156863 0 0 0 0.5 0"
						/>
						<feBlend
							mode="normal"
							in2="effect1_dropShadow_914_2569"
							result="effect2_dropShadow_914_2569"
						/>
						<feBlend
							mode="normal"
							in="SourceGraphic"
							in2="effect2_dropShadow_914_2569"
							result="shape"
						/>
					</filter>
					<linearGradient
						id="paint0_linear_914_2569"
						x1="408.5"
						y1="3.50001"
						x2="0.106745"
						y2="128.349"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0.0974484" stopColor="#042C86" />
						<stop offset="0.920861" stopColor="#0744A2" />
					</linearGradient>
					<linearGradient
						id="paint1_linear_914_2569"
						x1="127.857"
						y1="34.7642"
						x2="124.02"
						y2="38.8376"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0.176" stopColor="#0052CC" />
						<stop offset="1" stopColor="#2684FF" />
					</linearGradient>
					<linearGradient
						id="paint2_linear_914_2569"
						x1="123.518"
						y1="39.2894"
						x2="119.074"
						y2="43.7406"
						gradientUnits="userSpaceOnUse"
					>
						<stop offset="0.176" stopColor="#0052CC" />
						<stop offset="1" stopColor="#2684FF" />
					</linearGradient>
				</defs>
			</svg>
		</SlackSplashWrapper>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SlackSplashWrapper = styled.div({
	// We need to specify this since otherwise there will be a weird gap between the graphic
	// and the rest of the flag. Not sure what introduces the gap since all the margins / paddings / gap
	// properties look fine on all the elements
	height: 128,
});
