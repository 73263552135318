import { memoizedFetchSitePersonalization } from '@atlassian/jira-personalization-service/src/services/site/index.tsx';
import type { Trait } from '@atlassian/jira-personalization-service/src/types.tsx';
import type { CrossJoinTargetProduct } from '../../common/types.tsx';

const ACTIVATION_TRAITS: Record<CrossJoinTargetProduct, string> = {
	confluence: 'confluence.ondemand.activationDate',
	'jira-product-discovery': 'jira-product-discovery.activationDate',
};
export const isSite7DaysOld = async (product: CrossJoinTargetProduct, cloudId: string) => {
	const siteTraits = await memoizedFetchSitePersonalization({ cloudId });
	return findAndCheckActivationDate(product, siteTraits);
};

const findAndCheckActivationDate = (product: CrossJoinTargetProduct, traits: Trait[]): boolean => {
	const traitName = ACTIVATION_TRAITS[product] || undefined;
	if (!traitName) {
		throw Error(`Unknown activation trait key for site product ${product}`);
	}

	const activationTrait = traits.find((trait) => trait.name === traitName);

	if (activationTrait?.value) {
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		const oneWeekLaterActivation = new Date(activationTrait?.value as string);
		oneWeekLaterActivation.setDate(oneWeekLaterActivation.getDate() + 7);
		const today = new Date();
		return today >= oneWeekLaterActivation;
	}
	return false;
};
