import React, { type ReactNode } from 'react';
import { Section } from '@atlaskit/menu';
import { ContextualAnalyticsData } from '@atlassian/jira-product-analytics-bridge';

type RecommendationContainerProps = {
	attributes?: {
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		[key: string]: any;
	};
	children: ReactNode;
};

export const RecommendationContainer = ({
	children,
	attributes,
}: RecommendationContainerProps): ReactNode => (
	<ContextualAnalyticsData attributes={attributes}>
		<Section hasSeparator>{children}</Section>
	</ContextualAnalyticsData>
);
