import React, { useCallback, useEffect } from 'react';
import {
	useCrossJoinNudgeExperiment,
	useJpdCrossJoinNudge,
	useJpdCrossJoinNudgeEligibility,
} from '@atlassian/jira-cross-join-nudges/src/ui/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyAfterPaint } from '@atlassian/react-loosely-lazy';
import type { AppSwitcher as AppSwitcherType } from './main.tsx';
import type { AppSwitcherProps } from './types.tsx';
import { AppSwitcherLight } from './ui/app-switcher-light/index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const LazyAppSwitcher = lazyAfterPaint<typeof AppSwitcherType>(
	() =>
		import(/* webpackChunkName: "async-atlassian-navigation-app-switcher" */ './main').then(
			(module) => module.AppSwitcher,
		),
	{
		ssr: false,
	},
);

export const AppSwitcher = (props: AppSwitcherProps) => {
	const checkJpdCrossJoinNudgeEligibility = useJpdCrossJoinNudgeEligibility();
	const runConfluenceCrossJoinNudgeExperiment = useCrossJoinNudgeExperiment();
	const showJpdCrossJoinNudge = useJpdCrossJoinNudge();

	useEffect(() => {
		checkJpdCrossJoinNudgeEligibility().then((isEligible) =>
			isEligible ? showJpdCrossJoinNudge() : runConfluenceCrossJoinNudgeExperiment(),
		);
	}, [
		checkJpdCrossJoinNudgeEligibility,
		runConfluenceCrossJoinNudgeExperiment,
		showJpdCrossJoinNudge,
	]);

	const handleMouseEnter = useCallback(() => {
		LazyAppSwitcher.preload();
	}, []);

	return (
		<ErrorBoundary id="app-switcher.integration.async" packageName="NavigationIntegrations">
			<Placeholder name="lazy-app-switcher" fallback={<AppSwitcherLight />}>
				{props.isOpen ? (
					<LazyAppSwitcher {...props} />
				) : (
					<AppSwitcherLight onOpen={props.onOpen} onMouseEnter={handleMouseEnter} />
				)}
			</Placeholder>
		</ErrorBoundary>
	);
};
