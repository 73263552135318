/** @jsx jsx */
import React, { useEffect, useState, useMemo } from 'react';
import { css, jsx } from '@compiled/react';
import { EngagementSpotlight } from '@atlassiansox/engagekit-ts';
import Lozenge from '@atlaskit/lozenge';
import { HeadingItem, Section } from '@atlaskit/menu';
import { SpotlightTarget } from '@atlaskit/onboarding';
import { N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useUserTraits } from '@atlassian/jira-business-common/src/common/providers/controllers/traits/index.tsx';
import {
	SOFTWARE_PROJECT,
	CORE_PROJECT,
	SERVICE_DESK_PROJECT,
} from '@atlassian/jira-common-constants/src/project-types.tsx';
import { useExperienceSuccess } from '@atlassian/jira-experience-tracker/src/ui/experience-success/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { useProfileLink } from '@atlassian/jira-navigation-apps-common/src/utils/index.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import {
	getGeneralPersonalSettingsPath,
	getNotificationsPersonalSettingsPath,
} from '@atlassian/jira-personal-settings-paths/src/index.tsx';
import { useCurrentUser } from '@atlassian/jira-platform-services-user-current/src/main.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { peopleProfilesRoute } from '@atlassian/jira-router-routes-people-profiles-routes/src/peopleProfilesRoute.tsx';
import { generalPersonalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/generalPersonalSettingsRoute.tsx';
import { notificationsPersonalSettingsRoute } from '@atlassian/jira-router-routes-personal-settings-routes/src/notificationsPersonalSettingsRoute.tsx';
import { userBoardRoute } from '@atlassian/jira-router-routes-user-board-routes/src/userBoardRoute.tsx';
import { BUSINESS } from '@atlassian/jira-servicedesk-onboarding-checklist/src/services/get-onboarding-type/constants.tsx';
import { useJsmOnboardingType } from '@atlassian/jira-servicedesk-onboarding-checklist/src/services/get-onboarding-type/main.tsx';
import { OpenMenuItem as JswQuickstartOpenMenuItem } from '@atlassian/jira-software-onboarding-quickstart/src/ui/async.tsx';
import { useAccountId } from '@atlassian/jira-tenant-context-controller/src/components/account-id/index.tsx';
import { EXPERIENCE_NAVIGATION_TOP_MENU } from '../../../common/constants.tsx';
import { MenuItem } from '../../../common/ui/menu/item/index.tsx';
import { MenuLayout as Layout } from '../../../common/ui/menu/layout/index.tsx';
import { MenuList, MenuListItem } from '../../../common/ui/menu/list/index.tsx';
import { AccountDetails } from './account-details/index.tsx';
import { ExtraItems } from './extra-items/index.tsx';
import { ForgeItems } from './forge-items/index.tsx';
import messages from './messages.tsx';
import ThemeSwitcher from './theme-switcher-item/async.tsx';
import type { MenuProps } from './types.tsx';
import { UpgradeEditionMenuItem } from './upgrade-edition/main.tsx';

export const Menu = ({ testIdPrefix, userBoardId, userBoardOwnerAccountId }: MenuProps) => {
	const { formatMessage } = useIntl();
	const {
		data: { user },
	} = useCurrentUser();
	const accountId = useAccountId();
	const profileLink = useProfileLink();
	const onSuccess = useExperienceSuccess({
		experience: EXPERIENCE_NAVIGATION_TOP_MENU,
	});

	useEffect(() => {
		onSuccess();
	}, [onSuccess]);

	const profileParams = { subPath: accountId };
	const testId = testIdConcat(testIdPrefix, 'menu-popup');
	const testIdContent = testIdConcat(testId, 'content');
	const testIdFooter = testIdConcat(testId, 'footer');
	const [upgradeEditionMenuShown, setUpgradeEditionMenuShown] = useState<boolean>(false);
	const { data: projectContextData } = useProjectContext();
	const [{ data: jsmOnboardingData }, { fetchOnboardingType }] = useJsmOnboardingType();

	useEffect(() => {
		fetchOnboardingType(projectContextData?.projectId, projectContextData?.projectType);
	}, [fetchOnboardingType, projectContextData?.projectId, projectContextData?.projectType]);

	const showInJwmProjects = projectContextData?.projectType === CORE_PROJECT;
	const showInSoftwareProjects = projectContextData?.projectType === SOFTWARE_PROJECT;
	const showInJsmProjects =
		projectContextData?.projectType === SERVICE_DESK_PROJECT &&
		jsmOnboardingData?.onboardingType === BUSINESS;

	let yourBoardsRoute;
	let yourBoardsParams;
	let yourBoardsQuery;
	if (userBoardId != null) {
		yourBoardsRoute = userBoardRoute;
		yourBoardsParams = {
			accountId: userBoardOwnerAccountId,
			boardId: String(userBoardId),
		};
	}

	const boardOffset = userBoardId == null ? -1 : 0;

	const [userTraits] = useUserTraits();
	const showNotificationsNewLozenge = useMemo(() => {
		const accountCreationDate = userTraits?.attributes?.find(
			(trait: { name: string }) => trait?.name === 'jira_first_active',
		)?.value;

		const aWeekAgo = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
		let isNewUser = false;
		if (accountCreationDate && typeof accountCreationDate === 'string') {
			isNewUser = new Date(accountCreationDate) > aWeekAgo;
		}

		return !isNewUser;
	}, [userTraits]);

	useEffect(() => {
		// The Spotlight components listen to the resize event to
		// update their positioning. This will fix Spotlights pulse overlap
		// that are shown on Menu items. Without this the pulse are located
		/// at incorrect position.
		setTimeout(() => {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.dispatchEvent(new Event('resize'));
		}, 0);
	});

	return (
		<span
			data-testid={testId}
			role="dialog"
			aria-labelledby="atlassian-navigation-account-dialog-heading"
			css={styles}
		>
			<Layout>
				<Section data-testid={testIdConcat(testIdContent, 'account')}>
					<HeadingItem
						id="atlassian-navigation-account-dialog-heading"
						testId={testIdConcat(testIdContent, 'account', 'heading')}
					>
						{formatMessage(messages.account)}
					</HeadingItem>
					<AccountDetails
						// @ts-expect-error - TS2339 - Property 'emailAddress' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
						email={user.emailAddress || ''}
						userFullname={user.userFullname}
						// @ts-expect-error - TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'. | TS2339 - Property 'avatarUrls' does not exist on type 'DataBasic | (DataBasic & ResponseSuccess)'.
						avatarSrc={user.avatarUrls ? user.avatarUrls['32x32'] : undefined}
						testId={testIdConcat(testIdContent, 'account')}
					/>
				</Section>
				<Section hasSeparator={upgradeEditionMenuShown}>
					<UpgradeEditionMenuItem setUpgradeEditionMenuShown={setUpgradeEditionMenuShown} />
				</Section>
				<Section
					hasSeparator={upgradeEditionMenuShown || !upgradeEditionMenuShown}
					testId={testIdContent}
				>
					{(showInJwmProjects || showInSoftwareProjects || showInJsmProjects) && (
						<JswQuickstartOpenMenuItem MenuItem={MenuItem} />
					)}
					<span data-testid={testIdConcat(testIdContent, 'jira')}>
						<HeadingItem testId={testIdConcat(testIdContent, 'jira', 'heading')}>
							{formatMessage(messages.jira)}
						</HeadingItem>
						<MenuList>
							<MenuListItem ariaLabel={formatMessage(messages.jira)}>
								<MenuItem
									analytics={{
										actionSubjectId: 'profileNavigationMenuItem',
										menuItemId: 'profile',
										menuItemType: 'jira',
										position: 0,
									}}
									href={profileLink}
									params={profileParams}
									testId={testIdConcat(testIdContent, 'jira', 'item-profile')}
									to={peopleProfilesRoute}
								>
									{formatMessage(messages.profile)}
								</MenuItem>
							</MenuListItem>
							{userBoardId !== null && (
								<MenuListItem ariaLabel={formatMessage(messages.jira)}>
									<MenuItem
										analytics={{
											actionSubjectId: 'profileNavigationMenuItem',
											menuItemId: 'your-boards',
											menuItemType: 'jira',
											position: 1,
										}}
										href={`/jira/people/${String(userBoardOwnerAccountId)}/boards/${userBoardId}`}
										params={yourBoardsParams}
										query={yourBoardsQuery}
										testId={testIdConcat(testIdContent, 'jira', 'item-your-boards')}
										to={yourBoardsRoute}
									>
										{formatMessage(messages.yourBoards)}
									</MenuItem>
								</MenuListItem>
							)}
							<MenuListItem ariaLabel={formatMessage(messages.jira)}>
								<MenuItem
									analytics={{
										actionSubjectId: 'profileNavigationMenuItem',
										menuItemId: 'personal-settings',
										menuItemType: 'jira',
										position: 2 + boardOffset,
									}}
									href={getGeneralPersonalSettingsPath()}
									testId={testIdConcat(testIdContent, 'jira', 'item-personal-settings')}
									to={generalPersonalSettingsRoute}
								>
									{formatMessage(messages.personalSettings)}
								</MenuItem>
							</MenuListItem>
							<MenuListItem ariaLabel={formatMessage(messages.jira)}>
								<SpotlightTarget name="notifications-menu-spotlight">
									<MenuItem
										analytics={{
											actionSubjectId: 'profileNavigationMenuItem',
											menuItemId: 'notifications',
											menuItemType: 'jira',
											position: 3 + boardOffset,
										}}
										href={getNotificationsPersonalSettingsPath()}
										testId={testIdConcat(testIdContent, 'jira', 'item-notifications')}
										to={notificationsPersonalSettingsRoute}
										iconAfter={
											showNotificationsNewLozenge ? (
												<Lozenge appearance="new">{formatMessage(messages.new)}</Lozenge>
											) : null
										}
									>
										{formatMessage(messages.notifications)}
									</MenuItem>
									<EngagementSpotlight engagementId="notifications-menu-spotlight" />
								</SpotlightTarget>
							</MenuListItem>
							<MenuListItem ariaLabel={formatMessage(messages.jira)}>
								<ThemeSwitcher
									testId={testIdConcat(testIdContent, 'jira', 'item-theme')}
									itemPosition={4 + boardOffset}
								/>
							</MenuListItem>
							<ExtraItems
								startPosition={5 + boardOffset}
								testIdPrefix={testIdContent}
								itemAriaLabel={formatMessage(messages.jira)}
							/>
							{fg('forge-ui-project-web-items') && (
								<ForgeItems
									startPosition={6 + boardOffset}
									testIdPrefix={testIdContent}
									itemAriaLabel={formatMessage(messages.jira)}
								/>
							)}
						</MenuList>
					</span>
				</Section>

				<Section hasSeparator testId={testIdFooter}>
					<span data-testid={testIdConcat(testIdFooter, 'items')}>
						<MenuItem
							analytics={{
								actionSubjectId: 'profileNavigationMenuItem',
								menuItemId: 'logout',
								menuItemType: 'action',
								position: 0,
							}}
							href="/logout"
							testId={testIdConcat(testIdFooter, 'items', 'item-log-out')}
						>
							{formatMessage(messages.logout)}
						</MenuItem>
					</span>
				</Section>
			</Layout>
		</span>
	);
};
const styles = css({ color: token('color.text', N800) });
