import React, { memo } from 'react';

import { styled } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import {
	STAGING_ENVIRONMENT,
	DEVELOPMENT_ENVIRONMENT,
} from '@atlassian/jira-forge-ui-constants/src/constants.tsx';
import type { ExtensionEnvironment } from '@atlassian/jira-forge-ui-types/src/common/types/extension.tsx';
import { nameWithCustomEnvironment, EnvironmentLozenge } from './environment-lozenge/index.tsx';

export type Props = {
	extensionName: string;
	extensionEnvType: ExtensionEnvironment;
	extensionEnvKey?: string;
	autoTruncate?: boolean;
};

const shouldShowEnv = (extensionEnvType: ExtensionEnvironment) =>
	[STAGING_ENVIRONMENT, DEVELOPMENT_ENVIRONMENT].includes(extensionEnvType);

const ExtensionTitleText = memo(
	({ text, autoTruncate }: { text: string; autoTruncate: boolean }) => {
		if (!autoTruncate) {
			return <>{text} </>;
		}

		return <Box xcss={extensionTitleTextWrapperStyles}>{text}</Box>;
	},
);

const ExtensionTitle = ({
	extensionEnvType,
	extensionEnvKey,
	extensionName,
	autoTruncate,
}: Props) => {
	if (!shouldShowEnv(extensionEnvType)) {
		return <>{extensionName}</>;
	}

	const Wrapper = autoTruncate ? TruncatableExtensionTitleWrapper : ExtensionTitleWrapper;
	return (
		<Wrapper>
			<ExtensionTitleText
				text={nameWithCustomEnvironment(extensionName, extensionEnvType, extensionEnvKey)}
				autoTruncate={!!autoTruncate}
			/>
			<EnvironmentLozenge environmentType={extensionEnvType} />
		</Wrapper>
	);
};

export { ExtensionTitle };

const extensionTitleTextWrapperStyles = xcss({
	paddingRight: 'space.050',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flexGrow: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ExtensionTitleWrapper = styled.div({
	alignItems: 'baseline',
	/* The below selector targets span tag of <EnvironmentLozenge /> */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		flexShrink: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const TruncatableExtensionTitleWrapper = styled.div({
	display: 'flex',
	alignItems: 'baseline',
	/* The below selector targets span tag of <EnvironmentLozenge /> */
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& > span': {
		flexShrink: 0,
	},
});
