import { useCallback } from 'react';
import { UNSAFE_noExposureExp } from '@atlassian/jira-feature-experiments';
import { fireTrackAnalytics, useAnalyticsEvents } from '@atlassian/jira-product-analytics-bridge';

export const useCommerceExperienceEmbedExperiment = ({
	isCcpBillingSourceSystem = false,
	isJstoTarget = false,
}) => {
	const { createAnalyticsEvent } = useAnalyticsEvents();

	const [config, fireStatsigExposure] = UNSAFE_noExposureExp(
		'commerce_experience_payment_method_embed',
	);

	const cohort = config.get('cohort', 'not-enrolled');

	const isEligible = isCcpBillingSourceSystem && !isJstoTarget;
	const isVariation = isEligible && cohort === 'variation';

	const fireExposure = useCallback(() => {
		fireStatsigExposure();
		fireTrackAnalytics(createAnalyticsEvent({}), 'feature exposed', {
			flagKey: 'commerce_experience_payment_method_embed',
			value: cohort,
		});
	}, [fireStatsigExposure, createAnalyticsEvent, cohort]);

	return {
		isEligible,
		isVariation,
		fireExposure,
	};
};
