import React, { memo } from 'react';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import type { AtlaskitAtlassianNavigationNav4Props } from '@atlassian/jira-atlassian-navigation-nav4/src/ui/atlaskit-atlassian-navigation-nav4/types.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import { PACKAGE_NAME, TEAM_NAME } from './common/constants.tsx';

const LazyAtlaskitAtlassianNavigationNav4 = lazyForPaint(() =>
	import(
		/* webpackChunkName: "async-jira-atlassian-navigation-nav4" */ './ui/atlaskit-atlassian-navigation-nav4/AtlaskitAtlassianNavigationNav4.tsx'
	).then(({ AtlaskitAtlassianNavigationNav4 }) => AtlaskitAtlassianNavigationNav4),
);

const AsyncAtlaskitAtlassianNavigationNav4 = memo((props: AtlaskitAtlassianNavigationNav4Props) => (
	<JSErrorBoundary
		id="asyncAtlaskitAtlassianNavigationNav4"
		packageName={PACKAGE_NAME}
		teamName={TEAM_NAME}
	>
		<Placeholder name="lazyAtlaskitAtlassianNavigationNav4">
			<LazyAtlaskitAtlassianNavigationNav4 {...props} />
		</Placeholder>
	</JSErrorBoundary>
));

AsyncAtlaskitAtlassianNavigationNav4.displayName = 'AsyncAtlaskitAtlassianNavigationNav4';

export default AsyncAtlaskitAtlassianNavigationNav4;
