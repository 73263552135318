import { SERVICE_DESK_PROJECT } from '@atlassian/jira-common-constants/src/project-types.tsx';
import {
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_CONFIGURE,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_CONFIGURE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_HOME,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION,
	ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION,
} from '@atlassian/jira-common-constants/src/spa-routes.tsx';
import { useCurrentRoute } from '@atlassian/jira-platform-router-utils/src/index.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import {
	PREMIUM_EDITION,
	getEditionForProject,
} from '@atlassian/jira-shared-types/src/edition.tsx';
import { useAppEditions } from '@atlassian/jira-tenant-context-controller/src/components/app-editions/index.tsx';
import { useIsSiteAdmin } from '@atlassian/jira-tenant-context-controller/src/components/is-site-admin/index.tsx';

export const useJsmPremiumPillExperiment = (): {
	getIsEligibleForJsmPremiumPillExperiment: () => boolean;
	isOnCMDBPage: boolean;
} => {
	const appEditions = useAppEditions();
	const edition = getEditionForProject(SERVICE_DESK_PROJECT, appEditions);
	const { data: project } = useProjectContext();
	const isJSM = project?.projectType === SERVICE_DESK_PROJECT;
	const isSiteAdmin = useIsSiteAdmin();
	const route = useCurrentRoute();
	const isOnCMDBPage = isCMDBPage(route.name);

	const getIsEligibleForJsmPremiumPillExperiment = () => {
		if (edition !== PREMIUM_EDITION) {
			return false;
		}
		if (isOnCMDBPage && isSiteAdmin) {
			// only siteAdmins on CMDB pages are eligible
			return true;
		}
		if (!project) {
			return false;
		}
		if (!isJSM) {
			return false;
		}
		if (project.simplified) {
			// simplified project does not have the premium features
			return false;
		}
		const isProjectAdmin = !!project.isProjectAdmin;
		// finally check if they are an admin who can change features for the project
		return isProjectAdmin || isSiteAdmin;
	};

	return {
		getIsEligibleForJsmPremiumPillExperiment,
		isOnCMDBPage,
	};
};

// cleanup with jsm_premium_trial_pill_experiment
export const isCMDBPage = (routeName: string): boolean => {
	return [
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_CONFIGURE,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_SCHEMA_CONFIGURATION,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_OBJECT_TYPE_CONFIGURATION,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_DEFAULT_REDIRECT,
		ROUTE_NAMES_SERVICEDESK_CMDB_ASSETS_REPORTS_UNSUBSCRIBE,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_HOME,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_CONFIGURE,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_LANDING_PAGE,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_SCHEMA_CONFIGURATION,
		ROUTE_NAMES_SERVICEDESK_CMDB_INSIGHT_OBJECT_TYPE_CONFIGURATION,
	].includes(routeName);
};
