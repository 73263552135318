import React from 'react';
import {
	AppSwitcher as AkAppSwitcherNav3,
	AppSwitcherNav4 as AkAppSwitcherNav3WithIconRefresh,
} from '@atlaskit/atlassian-navigation';
import {
	SkeletonSwitcherButton,
	Nav4SkeletonSwitcherButton,
} from '@atlaskit/atlassian-navigation/skeleton-switcher-button';
import { fg } from '@atlassian/jira-feature-gating';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import { AppSwitcher as AkAppSwitcherNav4 } from '@atlassian/navigation-system/top-nav';
import { SwitchToTooltipText } from '@atlassian/switcher';

type Props = {
	onOpen?: () => void;
	onMouseEnter?: () => void;
};

/**
 * The app switcher button shown when the app switcher is closed.
 * This component is used for both nav3 and nav4.
 *
 * When open it is swapped out for the button defined in `@atlassian/jira-atlassian-switcher-common-integration-view`
 *
 * The swapping logic is defined in this package.
 */
export const AppSwitcherLight = ({ onOpen, onMouseEnter }: Props) => {
	if (__SERVER__) {
		if (getWillShowNav4()) {
			if (fg('jira_nav4_beta_drop_2')) {
				// TODO: when cleaning up we can probably remove the Nav 4 server branch
				return <AkAppSwitcherNav4 label={SwitchToTooltipText} />;
			}

			return <Nav4SkeletonSwitcherButton label="" />;
		}
		return <SkeletonSwitcherButton label="" />;
	}

	if (getWillShowNav4()) {
		if (fg('jira_nav4_eap_drop_2')) {
			return (
				<AkAppSwitcherNav4
					onClick={onOpen}
					onMouseEnter={onMouseEnter}
					label={SwitchToTooltipText}
					aria-expanded={fg('blu-4616-a11y-fix-for-app-switcher') ? false : undefined}
					aria-haspopup={fg('blu-4616-a11y-fix-for-app-switcher') ? true : undefined}
				/>
			);
		}

		// @ts-expect-error - TS2741 - Property 'tooltip' is missing in type '{ onClick: (() => void) | undefined; }' but required in type 'BaseIconButtonProps'.
		return <AkAppSwitcherNav3WithIconRefresh onClick={onOpen} onMouseEnter={onMouseEnter} />;
	}
	// @ts-expect-error - TS2741 - Property 'tooltip' is missing in type '{ onClick: (() => void) | undefined; }' but required in type 'BaseIconButtonProps'.
	return <AkAppSwitcherNav3 onClick={onOpen} onMouseEnter={onMouseEnter} />;
};
