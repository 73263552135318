import React, { useEffect, useCallback } from 'react';
import { Section } from '@atlaskit/menu';
import { AuthenticationErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/authentication-error-boundary/AuthenticationErrorBoundary.tsx';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import { useExperienceFail } from '@atlassian/jira-experience-tracker/src/ui/experience-fail/index.tsx';
import { useFavoriteDashboards as useFavorite } from '@atlassian/jira-favorites-store/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { testIdConcat } from '@atlassian/jira-navigation-apps-common/src/utils/test-id.tsx';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import {
	DEFAULT_LOAD_COUNT_FAV,
	PACKAGE_NAME,
	EXPERIENCE_NAVIGATION_TOP_MENU,
} from '../../../common/constants.tsx';
import { MenuError as Error } from '../../../common/ui/menu/error/main.tsx';
import { MenuLayout as Layout } from '../../../common/ui/menu/layout/index.tsx';
import { MenuSkeletonContent as SkeletonContent } from '../../../common/ui/menu/skeleton/content/main.tsx';
import { MenuSkeletonFooter as SkeletonFooter } from '../../../common/ui/menu/skeleton/footer/main.tsx';
import { Empty } from './empty/main.tsx';
import { FavoriteContent } from './favorite-content/main.tsx';
import { Footer } from './footer/main.tsx';
import { LoadStatus } from './load-status/main.tsx';
import { RecentContent } from './recent-content/main.tsx';
import type { MenuProps } from './types.tsx';

const EVENT_DATA = { id: MENU_ID.DASHBOARDS } as const;

export const Menu = ({ testIdPrefix }: MenuProps) => {
	const [, { loadFavoriteDashboards: loadFavorite }] = useFavorite();
	const onFail = useExperienceFail({ experience: EXPERIENCE_NAVIGATION_TOP_MENU });

	useEffect(
		() => () => {
			// Refresh favourites on close to update if user has toggled any item
			loadFavorite(DEFAULT_LOAD_COUNT_FAV);
		},
		[loadFavorite],
	);

	const testIdContent = testIdConcat(testIdPrefix, 'content');
	const testIdFooter = testIdConcat(testIdPrefix, 'footer');

	const errorFallback = useCallback(() => <Error testIdPrefix={testIdContent} />, [testIdContent]);

	return (
		<Layout isWide>
			<Section isScrollable>
				<JSErrorBoundary
					id={MENU_ID.DASHBOARDS}
					packageName={PACKAGE_NAME}
					fallback={errorFallback}
					withExperienceTracker
					extraEventData={EVENT_DATA}
					onError={onFail}
				>
					<AuthenticationErrorBoundary render={errorFallback}>
						<Empty testIdPrefix={testIdContent}>
							<Placeholder
								name="navigation-apps-dashboards-menu"
								fallback={<SkeletonContent testIdPrefix={testIdContent} />}
							>
								<LoadStatus shouldThrowError />
								<FavoriteContent testIdPrefix={testIdContent} />
								<RecentContent testIdPrefix={testIdContent} />
							</Placeholder>
						</Empty>
					</AuthenticationErrorBoundary>
				</JSErrorBoundary>
			</Section>
			<Section hasSeparator>
				<Placeholder
					name="navigation-apps-dashboards-footer"
					fallback={<SkeletonFooter testIdPrefix={testIdFooter} />}
				>
					<LoadStatus />
					<Footer testIdPrefix={testIdFooter} />
				</Placeholder>
			</Section>
		</Layout>
	);
};
