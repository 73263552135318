import React from 'react';
import { AlertsStoreContainer } from '@atlassian/jira-alerts-store/src/main.tsx';
import { useIntl } from '@atlassian/jira-intl';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { useActivityClient } from '@atlassian/recent-work-client';
import { NAVIGATION_ITEM_ID } from '../../common/constants.tsx';
import { TopLevelErrorBoundary } from '../../common/ui/error-boundary/index.tsx';
import PopupBoxWrapper from '../../common/ui/popup-box-wrapper/index.tsx';
import { Popup } from '../../common/ui/popup/main.tsx';
import { RecentActivityContainer } from '../../controllers/your-work-recent-activities/index.tsx';
import { AsyncMenu } from './async.tsx';
import messages from './messages.tsx';

const YourWorkDropdown = () => {
	const { formatMessage } = useIntl();

	const activityClient = useActivityClient('v3', 'jira');
	const cloudId = useCloudId();

	return (
		<AlertsStoreContainer cloudId={cloudId}>
			<RecentActivityContainer activityClient={activityClient} cloudId={cloudId}>
				<PopupBoxWrapper>
					<Popup
						menuId={MENU_ID.HOME}
						navigationItemId={NAVIGATION_ITEM_ID.HOME}
						Menu={AsyncMenu}
						label={formatMessage(messages.title)}
					>
						{formatMessage(messages.title)}
					</Popup>
				</PopupBoxWrapper>
			</RecentActivityContainer>
		</AlertsStoreContainer>
	);
};

const YourWorkDropdownWithErrorBoundary = () => (
	<TopLevelErrorBoundary id={MENU_ID.HOME}>
		<YourWorkDropdown />
	</TopLevelErrorBoundary>
);

export { YourWorkDropdownWithErrorBoundary as YourWorkDropdown };
